import React from 'react';
import { useTypedSelector } from '../../redux/reducers';
import { Button } from '../button/Button';
import s from './HomepageVisirHeader.module.css';
import cn from 'classnames';
import { TailwindContainer } from '../tailwind-container/TailwindContainer';
export type HomepageVisirHeaderProps = {};
export const HomepageVisirHeader: React.FC<HomepageVisirHeaderProps> = ({}) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  return (
    <div className="bg-black">
      <TailwindContainer className="text-white">
        <div className="flex flex-col-reverse lg:flex-row text-center lg:text-left">
          <div className="py-10 lg:py-20 mx-auto max-w-lg lg:max-w-xl lg:pr-10">
            <h1 className="text-3xl lg:text-6xl uppercase">
              Heimili <span className="text-purple-500">íslenskra</span>{' '}
              hlaðvarpa
            </h1>
            <div className="mt-5 leading-7">
              Hér getur þú uppgötvað íslensk hlaðvörp, fylgst með hvað er
              vinsælast og fengið aukaefni. Tal býður skapandi einstaklingum
              tækifæri á að byggja upp sitt eigið samfélag og hjálpar þeim að
              stækka.
            </div>
            <div className="mt-8">
              {auth.isLoaded && auth.isEmpty && (
                <>
                  <Button to={'/login'}>Innskráning</Button>
                </>
              )}
            </div>
          </div>
          <div className={cn(s.right, 'flex-grow pt-10 lg:pt-0')}>
            <img
              src={'https://pardus-prod.imgix.net/logo/tal-header.png?w=810'}
              alt={''}
              className="max-h-72 mx-auto lg:hidden"
            />
          </div>
        </div>
      </TailwindContainer>
    </div>
  );
};
