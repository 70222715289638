import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Switch } from '@/components/ui/switch';

export const FormToggle = ({
  name,
  label,
}: {
  name: string;
  label?: string;
}) => {
  const { watch, setValue } = useFormContext();
  const checked = !!watch(name);
  return (
    <div className={'flex gap-2 items-center'}>
      {label && (
        <div>
          <label className={'cursor-pointer'} onClick={() => setValue(name, !checked)}>
            {label}
          </label>
        </div>
      )}
      <Switch
        checked={checked}
        onCheckedChange={(val) => {
          setValue(name, val);
        }}
      />
    </div>
  );
};
