import { Form } from '@/components/react-form-utils/Form';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { EpisodesFormV2 } from '@/components/episode-form-v2/EpisodesFormV2';
import { PageContainer } from '@/components/page-container/PageContainer';
import { TopNav } from '@/components/top-nav/TopNav';
import React from 'react';
import { ManagePodcastTab, Podcast } from '@/types';
import { ManagePodcast } from '@/components/manage-podcast/ManagePodcast';
import withPodcast from '@/utils/withPodcast';
import { useRequiredParam } from '@/hooks/use-required-param';
import { scheduleEpisodeFormSchema, scheduleSchema } from '@/schemas/schedules';
import { Redirect } from 'react-router';
import { useCreateSchedule } from '@/queries/schedules';
import { zodParse } from '@/utils/zod-utils';

const PodcastUploadsNew = (props: { podcast: Podcast }) => {
  const { podcast } = props;
  const form = useForm<z.infer<typeof scheduleEpisodeFormSchema>>({
    resolver: zodResolver(scheduleEpisodeFormSchema),
    defaultValues: {
      feed: {
        enabled: false,
        content: '',
        allowComments: false,
      },
      email: {
        enabled: false,
        subject: '',
        content: '',
      },
      schedule: {
        enabled: false,
        publishAt: new Date(),
      },
    },
  });
  const podcastId = useRequiredParam('podcastId');
  const { mutate, isPending, isSuccess } = useCreateSchedule(podcastId);
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.UPLOADS} podcast={podcast}>
        <Form
          form={form}
          onSubmit={(data) =>
            mutate(
              zodParse(scheduleSchema, {
                type: 'EPISODE',
                publishAt: data.schedule.enabled
                  ? data.schedule.publishAt
                  : new Date(),
                episode: data.episode,
                ...(data.feed.enabled && { feed: data.feed }),
                ...(data.email.enabled && { email: data.email }),
              }),
            )
          }
        >
          <EpisodesFormV2 title={'Nýr þáttur'} isLoading={isPending} />
        </Form>
        {isSuccess && <Redirect to={`/podcasts/uploads/${podcastId}`} />}
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(PodcastUploadsNew);
