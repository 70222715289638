import React, { useMemo, useState } from 'react';
import s from './EpisodeForm.module.css';
import { Center } from '../position/Center';
import { TitleCard } from '../title-card/TitleCard';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { Episode, ModalType } from '../../types';
import { UploadSoundFile } from '../upload-sound-file/UploadSoundFile';
import { UploadPicture } from '../upload-picture/UploadPicture';
import { Link } from 'react-router-dom';
import { showModal } from '../../redux/actions/modal';
import { FormCheckbox } from '../form-fields/FormCheckbox';
import { DateTimePickerInput } from '../date-time-picker-input/DateTimePickerInput';
import Switch from 'react-switch';
import { isAfter } from 'date-fns';
import { Textarea } from '../textarea/Textarea';

type Props = {
  episode?: Episode;
  save: (episode: Episode) => void;
  title: string;
  podcastId: string;
  isLoading?: boolean;
  disclaimer?: string;
};

export const EpisodeForm: React.FC<Props> = ({
  episode,
  save,
  title,
  podcastId,
  isLoading,
  disclaimer,
}) => {
  const now = useMemo(() => new Date(), []);
  const [name, setName] = useState<string>(episode?.name || '');
  const [url, setUrl] = useState<string>(episode?.url || '');
  const [img, setImg] = useState<string>(episode?.img || '');
  const [isOpen, setIsOpen] = useState(episode?.isOpen || false);
  const [description, setDescription] = useState(episode?.description || '');
  const [displayDate, setDisplayDate] = useState<Date | null>(
    episode?.displayDate?.toDate() || new Date()
  );
  const [publishType, setPublishType] = useState<'NOW' | 'CUSTOM'>(
    episode?.displayDate && isAfter(episode.displayDate.toDate(), new Date())
      ? 'CUSTOM'
      : 'NOW'
  );
  const onSave = () => {
    const saveObj: any = {
      name,
      url,
      podcastId,
      img,
      isOpen,
      displayDate,
      description,
    };
    if (episode?.id) {
      saveObj.id = episode.id;
    }
    save(saveObj);
  };

  return (
    <Center>
      <TitleCard title={title}>
        <TextInput
          autofocus
          value={name}
          label={'Nafn'}
          placeholder={'Skrifaðu nafn þáttar'}
          onChange={(val) => setName(val)}
        />
        <div className={'mt-4'}>
          <Textarea
            value={description}
            onChange={(val) => setDescription(val)}
            label={'Lýsing'}
            placeholder={'Skrifaðu lýsingu á þættinum'}
          />
        </div>
        <div className={s.inputs}>
          <UploadSoundFile url={url} save={(link) => setUrl(link)} />
          <div style={{ marginTop: '1rem' }}>
            <UploadPicture
              aspectRatio={1}
              url={img}
              label={''}
              save={async (link) => setImg(link)}
            />
          </div>
        </div>
        <div className={s.isOpen}>
          <FormCheckbox
            checked={isOpen}
            onChange={(e: any) => setIsOpen(e.target.checked)}
          >
            Opið efni
          </FormCheckbox>
        </div>
        <div className={s.actions}>
          <Button className={'gray'} to={`/podcasts/uploads/${podcastId}?tab=published`}>
            Til baka
          </Button>
          <Button
            disabled={isLoading || !name || !url || !img}
            onClick={onSave}
          >
            {isLoading ? 'Hinkraðu...' : 'Vista'}
          </Button>
        </div>
        {disclaimer &&
          (publishType === 'NOW' || !displayDate || displayDate < now) && (
            <div className={s.disclaimer}>{disclaimer}</div>
          )}
      </TitleCard>
    </Center>
  );
};
