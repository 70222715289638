import React from 'react';
import s from './ManageEpisodesList.module.css';
import { SimpleTable } from '../simple-table/SimpleTable';
import { Episode, ModalType } from '../../types';
import { Link } from 'react-router-dom';
import { ContextMenu } from '../context-menu/ContextMenu';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { format, isBefore } from 'date-fns';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TbDots } from 'react-icons/tb';

type Props = {
  episodes: Episode[];
  podcastId: string;
  deleteEpisode: (episode: Episode) => () => Promise<void>;
};

export const ManageEpisodesLists: React.FC<Props> = ({
  episodes,
  podcastId,
  deleteEpisode,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Dagsetning</TableHead>
            <TableHead>Þáttur</TableHead>
            <TableHead>Stakar hlustanir</TableHead>
            <TableHead>Allar hlustanir</TableHead>
            <TableHead>Aðgangur</TableHead>
            <TableHead className={'text-right'}></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {episodes.map((e) => (
            <TableRow>
              <TableCell>
                {format(e.createdAt!.toDate(), 'dd. MMM yyyy HH:mm')}
              </TableCell>
              <TableCell>{e.name}</TableCell>
              <TableCell>{e.played || 0}</TableCell>
              <TableCell>{e.totalPlayed || 0}</TableCell>
              <TableCell>{e.isOpen ? 'Opinn' : 'Aðeins áskrifendur'}</TableCell>
              <TableCell className={'text-right'}>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      variant={'ghost'}
                      className={
                        'px-3 focus-visible:ring-0 focus-visible:outline-none hover:cursor-pointer'
                      }
                    >
                      <TbDots size={18} />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem asChild>
                      <Link to={`/episodes/${e.id}/edit`}>Breyta</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      variant={'destructive'}
                    >
                      <button
                        onClick={(event) => {
                          dispatch(
                            showModal(ModalType.CONFIRM_MODAL, {
                              title: 'Eyða þætti',
                              actionText: 'Eyða',
                              action: deleteEpisode(e),
                              body: 'Ertu viss um að þú viljir eyða?',
                            }),
                          )
                        }}
                        className={'cursor-pointer w-full text-left'}
                      >
                        Eyða
                      </button>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
