import React, { useEffect } from 'react';
import withPodcast from '../utils/withPodcast';
import { ManagePodcastTab, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';
import { useEditSchedule, useSchedule } from '@/queries/schedules';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { scheduleEpisodeFormSchema, scheduleSchema } from '@/schemas/schedules';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRequiredParam } from '@/hooks/use-required-param';
import { Form } from '@/components/react-form-utils/Form';
import { EpisodesFormV2 } from '@/components/episode-form-v2/EpisodesFormV2';
import { Redirect } from 'react-router';
import { zodParse } from '@/utils/zod-utils';

const EditEpisodeSchedule = (props: { podcast: Podcast }) => {
  const { podcast } = props;
  const form = useForm<z.infer<typeof scheduleEpisodeFormSchema>>({
    resolver: zodResolver(scheduleEpisodeFormSchema),
  });
  const scheduleId = useRequiredParam('scheduleId');
  const { mutate, isPending, isSuccess } = useEditSchedule(scheduleId);
  const { data, isLoading } = useSchedule(scheduleId);
  useEffect(() => {
    if (data?.type !== 'EPISODE') {
      return;
    }
    if (!isLoading && data && !form.formState.isDirty) {
      form.reset({
        episode: data.episode,
        email: {
          content: '',
          subject: '',
          ...data.email,
          enabled: !!data.email,
        },
        feed: {
          content: '',
          allowComments: false,
          ...data.feed,
          enabled: !!data.feed,
        },
        schedule:
          data.publishAt > new Date()
            ? { publishAt: data.publishAt, enabled: true }
            : { publishAt: new Date(), enabled: false },
      });
    }
  }, [isLoading, data, form]);
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.UPLOADS} podcast={podcast}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Form
            form={form}
            onSubmit={(data) =>
              mutate(
                zodParse(scheduleSchema, {
                  type: 'EPISODE',
                  publishAt: data.schedule.enabled
                    ? data.schedule.publishAt
                    : new Date(),
                  episode: data.episode,
                  ...(data.feed.enabled && { feed: data.feed }),
                  ...(data.email.enabled && { email: data.email }),
                }),
              )
            }
          >
            <EpisodesFormV2 title={'Breyta þætti'} isLoading={isPending} />
          </Form>
        )}
        {isSuccess && <Redirect to={`/podcasts/uploads/${podcast.id}`} />}
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(EditEpisodeSchedule);
