import { useTypedSelector } from '../../redux/reducers';
import { Spinner } from '../../components/spinner/Spinner';
import { LoginExternalNoAuth } from './LoginExternalNoAuth';
import { LoginExternalWithAuth } from './LoginExternalWithAuth';
import { PropsWithChildren } from 'react';

export const LoginExternalContainer = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={
        'text-center w-[100vw] bg-[#1E1E1E] text-white h-[100vh] flex items-center justify-center pb-8'
      }
    >
      <div className={'max-w-[640px]'}>
        {children}
      </div>
    </div>
  );
};
export const LoginExternal = () => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  if (!auth.isLoaded) {
    return (
      <LoginExternalContainer>
        <Spinner />
      </LoginExternalContainer>
    );
  }

  if (auth.isEmpty) {
    return <LoginExternalNoAuth />;
  }
  return <LoginExternalWithAuth />;
};
