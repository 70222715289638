import { Form } from '@/components/react-form-utils/Form';
import clientConfig from '../../client-config';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { externalLoginSchema } from '../../schemas/external-login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useExternalLoginMutation } from '../../queries/external-login';
import { Spinner } from '../../components/spinner/Spinner';
import { usePostToken } from './use-post-token';
import { useFirebase } from 'react-redux-firebase';
import { error } from '../../utils/Notifications';
import cn from 'classnames';
import { FormInput } from "@/components/react-form-utils/FormInput";

export const LoginExternalNoAuth = () => {
  const isVisir = clientConfig.name === 'visir';
  const firebase = useFirebase();
  const form = useForm<z.infer<typeof externalLoginSchema>>({
    resolver: zodResolver(externalLoginSchema),
  });
  const {
    mutate,
    isSuccess,
    isPending,
    data,
    error: mutationError,
  } = useExternalLoginMutation();
  usePostToken(data?.token);
  if (isSuccess) {
    return (
      <div className={'text-center p-8'}>
        <Spinner />
      </div>
    );
  }
  return (
    <div
      className={
        'p-2 bg-[#1E1E1E] min-h-[100vh] mx-auto text-white'
      }
    >
      <div className={'max-w-[640px] mx-auto'}>
        <div className={'text-2xl font-bold text-center py-4'}>Innskráning í {clientConfig.name === 'visir' ? 'TAL' : 'Pardus'}</div>
        <Form
          onSubmit={async (d) => {
            try {
              await firebase.login(d);
              mutate(d);
            } catch (err: any) {
              error('Notandanafn og lykilorð passa ekki saman.');
            }
          }}
          form={form}
          className={'space-y-4 mt-4'}
        >
          <FormInput name={'email'} label={'Netfang'} className={'text-black'} />
          <FormInput
            name={'password'}
            label={'Lykilorð'}
            className={'text-black'}
            type={'password'}
          />
          <div className={'pt-2'}>
            <button
              type={'submit'}
              disabled={isPending}
              className={cn(
                'bg-[#EB4889] hover:bg-[#B01352] transition-colors rounded-md font-bold',
                'px-6 py-3',
              )}
            >
              Áfram
            </button>
          </div>
          {mutationError?.message && (
            <div className={'p-4 text-red-500'}>
              {mutationError.message}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
