import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FrontCategoryLayout, Podcast } from '../../types';
import { useWindowSize } from '../../hooks/use-window.size';
import s from './FrontCategoryItem.module.css';
import cn from 'classnames';

export type FrontCategoryItemProps = {
  layout: FrontCategoryLayout;
  title: string;
  podcasts: Podcast[];
};
export const FrontCategoryItem: React.FC<FrontCategoryItemProps> = ({
  layout,
  title,
  podcasts,
}) => {
  const [computedAr, setComputedAr] = useState(0);
  const [width] = useWindowSize();
  const ar = layout === FrontCategoryLayout.LARGE ? '0.6475:1' : '1.55:1';
  const height =
    width < 576 ? 320 : layout === FrontCategoryLayout.LARGE ? 400 : 167;
  const imgContainerRef = useRef(null);
  useLayoutEffect(() => {
    if (imgContainerRef?.current) {
      const el: any = imgContainerRef?.current;
      const elHeight = el?.getBoundingClientRect().height;
      const elWidth = el?.getBoundingClientRect().width;
      if (elHeight && elWidth) {
        setComputedAr(elWidth / elHeight);
      }
    }
  }, [imgContainerRef, width]);
  return (
    <section className={'mt-5 flex flex-col sm:block'}>
      <h2 className="text-2xl">{title}</h2>
      <div className="w-full sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {podcasts.map((podcast) => (
          <Link
            to={`/${podcast.slug}`}
            key={podcast.id}
            className="mt-3 block sm:mt-0 cursor-pointer"
          >
            <div
              ref={imgContainerRef}
              className={cn(s.imgContainer, 'bg-gray-200 min-w-full')}
              style={{
                minHeight: `${height}px`,
                backgroundImage: `url(${podcast.image}?h=${height}&ar=${
                  computedAr || ar
                }&fit=crop&crop=top)`,
              }}
            />
            <div className="mt-4 text-black">
              <strong>{podcast.name}</strong>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};
