import { FormTextArea } from '@/components/react-form-utils/FormTextArea';
import { Button } from '@/components/ui/button';
import { FormToggle } from '@/components/react-form-utils/FormToggle';
import React, { useEffect, useRef } from 'react';
import { useUploadFile } from '@/hooks/use-upload-file';
import { Progress } from '@/components/ui/progress';
import { TrashIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import z from 'zod';
import { postToFeedSchema } from '@/schemas/feeds';

export const PostToFeedV2 = () => {
  const {
    mutation: { mutate, isPending, data, reset, isIdle, isSuccess },
    uploadProgress,
    uploadTask,
  } = useUploadFile();
  const form = useFormContext<z.infer<typeof postToFeedSchema>>();
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isIdle && fileRef.current) {
      fileRef.current.value = '';
    }
  }, [isIdle, fileRef]);

  useEffect(() => {
    if (isSuccess) {
      form.setValue('feed.name', data.name);
      form.setValue('feed.url.src', data.url);
    }
  }, [isSuccess]);

  const fileName = form.watch('feed.name');
  return (
    <div className={'space-y-4'}>
      <FormTextArea
        name={'feed.content'}
        className={'resize-none min-h-[120px]'}
        placeholder={'Skrifaðu hér...'}
      />
      <div className={'flex gap-4 items-center'}>
        {fileName ? (
          <div className={'text-sm flex items-center gap-2'}>
            <button
              type={'button'}
              className={'px-1 -mr-1'}
              onClick={() => {
                form.setValue('feed.name', '');
                form.setValue('feed.url', undefined);

                reset();
              }}
            >
              <TrashIcon size={16} />
            </button>
            {fileName}
          </div>
        ) : (
          <Button
            variant={'outline'}
            onClick={() => fileRef.current?.click()}
            disabled={isPending}
          >
            Velja myndband eða mynd (valkvætt)
          </Button>
        )}
        <FormToggle name={'feed.allowComments'} label={'Leyfa athugasemdir'} />
        <input
          ref={fileRef}
          className="hidden"
          type="file"
          accept="video/*,image/*"
          onChange={async (e) => {
            const file = e.target.files?.[0];
            if (file && !isPending) {
              const isImage = file.type.startsWith('image/');
              form.setValue('feed.url.type', isImage ? 'IMAGE' : 'VIDEO');
              mutate({ file, urlType: isImage ? 'IMGIX' : 'STORAGE' });
            }
          }}
        />
      </div>
      {!!uploadProgress && (
        <div>
          <div className={'flex items-center gap-2'}>
            <Progress value={uploadProgress} />
            {Math.round(uploadProgress)}%
          </div>
          <Button
            onClick={() => {
              uploadTask?.cancel();
              form.setValue('feed.name', '');
              form.setValue('feed.url', undefined);
              reset();
            }}
          >
            Hætta við
          </Button>
        </div>
      )}
    </div>
  );
};
