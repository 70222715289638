import { useRequiredParam } from '@/hooks/use-required-param';
import { useDeleteSchedule, useSchedules } from "@/queries/schedules";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Spinner } from '@/components/spinner/Spinner';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { showModal } from '@/redux/actions/modal';
import { ModalType } from '@/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TbDots } from 'react-icons/tb';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

export const EpisodesSchedulesList = () => {
  const podcastId = useRequiredParam('podcastId');
  const dispatch = useDispatch();
  const { data, isLoading } = useSchedules(podcastId, 'EPISODE');
  const { mutate } = useDeleteSchedule();
  if (isLoading || !data) {
    return <Spinner />;
  }
  if (!data.length) {
    return (
      <div className={'text-center p-4 mt-2'}>Engir þættir eru í bið.</div>
    );
  }
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Þáttur</TableHead>
            <TableHead>Birting</TableHead>
            <TableHead className="text-right"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data
            .filter((schedule) => schedule.type === 'EPISODE')
            .map((schedule) => (
              <TableRow key={schedule.id}>
                <TableCell className="font-medium">
                  {schedule.episode.name}
                </TableCell>
                <TableCell>
                  {format(schedule.publishAt, 'dd. MMM yyyy HH:mm')}
                </TableCell>
                <TableCell className="text-right">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        variant={'ghost'}
                        className={
                          'px-3 focus-visible:ring-0 focus-visible:outline-none hover:cursor-pointer'
                        }
                      >
                        <TbDots size={18} />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem asChild>
                        <Link
                          to={`/podcasts/${podcastId}/episodes/schedules/${schedule.id}`}
                        >
                          Breyta
                        </Link>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        variant={'destructive'}
                        className={'hover:cursor-pointer'}
                        onClick={() =>
                          dispatch(
                            showModal(ModalType.CONFIRM_MODAL, {
                              title: 'Eyða þætti',
                              actionText: 'Eyða',
                              action: () => mutate(schedule.id!),
                              body: 'Ertu viss um að þú viljir eyða?',
                            }),
                          )
                        }
                      >
                        Eyða
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
