import React from 'react';
import cn from 'classnames';
import s from './EventButton.module.css';

export const EventButton = ({ url, text }: { url: string; text: string }) => {
  return (
    <div>
      <a
        href={url}
        className={cn(
          s.eventBtn,
          'mx-auto',
          'bg-red-600',
          'text-white',
          'block',
          'text-center',
          'p-3',
          'min-h-[80px]',
          'flex',
          'items-center',
          'justify-center',
          'hover:bg-red-600',
          'border',
          'border-red-600',
          'font-bold',
          'uppercase',
        )}
      >
        <span className="text-white">{text}</span>
      </a>
    </div>
  );
};
