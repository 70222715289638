import withPodcast from '../utils/withPodcast';
import { PageContainer } from '../components/page-container/PageContainer';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { ManagePodcastTab, ModalType, Podcast } from '../types';
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  useCreateSchedule,
  useDeleteSchedule,
  useSchedules,
} from '@/queries/schedules';
import { Spinner } from '@/components/spinner/Spinner';
import { useRequiredParam } from '@/hooks/use-required-param';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { format } from 'date-fns';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { TbDots } from 'react-icons/tb';
import { showModal } from '@/redux/actions/modal';
import { useForm } from 'react-hook-form';
import z from 'zod';
import {
  scheduleEmailFormSchema,
  scheduleEpisodeFormSchema,
  scheduleSchema,
} from '@/schemas/schedules';
import { zodResolver } from '@hookform/resolvers/zod';
import { NotificationsForm } from '@/components-v2/NotificationsForm';
import { zodParse } from '@/utils/zod-utils';
import { Form } from '@/components/react-form-utils/Form';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

const ManagePodcastEmail: React.FC<any> = (props) => {
  const params = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const {
    podcast,
  }: {
    podcast: Podcast;
  } = props;

  const form = useForm<z.infer<typeof scheduleEmailFormSchema>>({
    resolver: zodResolver(scheduleEmailFormSchema),
    defaultValues: {
      publishAt: new Date(),
    },
  });

  const { mutate, isPending, isSuccess } = useCreateSchedule(podcast.id!);

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      history.push(`/podcasts/email/${podcast.id}`);
    }
  }, [isSuccess]);

  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.EMAIL} podcast={podcast}>
        <Tabs value={params.get('tab') || 'schedules'}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              value="schedules"
              onClick={() => history.push(`/podcasts/email/${podcast.id}`)}
            >
              Tilkynningar í bið
            </TabsTrigger>
            <TabsTrigger
              value="new-notification"
              onClick={() =>
                history.push(
                  `/podcasts/email/${podcast.id}?tab=new-notification`,
                )
              }
            >
              Ný tilkynning
            </TabsTrigger>
          </TabsList>
          <TabsContent value="schedules">
            <NotificationsSchedulesList />
          </TabsContent>
          <TabsContent value={'new-notification'}>
            <Form
              form={form}
              onSubmit={(data) =>
                mutate(
                  zodParse(scheduleSchema, {
                    type: 'EMAIL',
                    publishAt: data.publishAt,
                    email: {
                      content: data.content,
                      subject: data.subject,
                    },
                  }),
                )
              }
            >
              <NotificationsForm
                title={'Ný tilkynning'}
                isLoading={isPending}
              />
            </Form>
          </TabsContent>
        </Tabs>
      </ManagePodcast>
    </PageContainer>
  );
};

const NotificationsSchedulesList = () => {
  const podcastId = useRequiredParam('podcastId');
  const { data, isLoading } = useSchedules(podcastId, 'EMAIL');
  const { mutate } = useDeleteSchedule();
  const dispatch = useDispatch();
  if (isLoading || !data) {
    return <Spinner />;
  }
  if (!data.length) {
    return (
      <div className={'text-center p-4 mt-2'}>
        Engar tilkynningar eru í bið.
      </div>
    );
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Tilkynning</TableHead>
            <TableHead>Tímasetning</TableHead>
            <TableHead className="text-right"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data
            .filter((schedule) => schedule.type === 'EMAIL')
            .map((schedule) => (
              <TableRow key={schedule.id}>
                <TableCell className="font-medium">
                  {schedule.email.subject}
                </TableCell>
                <TableCell>
                  {format(schedule.publishAt, 'dd. MMM yyyy HH:mm')}
                </TableCell>
                <TableCell className="text-right">
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                        variant={'ghost'}
                        className={
                          'px-3 focus-visible:ring-0 focus-visible:outline-none hover:cursor-pointer'
                        }
                      >
                        <TbDots size={18} />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        variant={'destructive'}
                        className={'hover:cursor-pointer'}
                        onClick={() =>
                          dispatch(
                            showModal(ModalType.CONFIRM_MODAL, {
                              title: 'Eyða tilkynningu',
                              actionText: 'Eyða',
                              action: () => mutate(schedule.id!),
                              body: 'Ertu viss um að þú viljir eyða?',
                            }),
                          )
                        }
                      >
                        Eyða
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default withPodcast(ManagePodcastEmail);
