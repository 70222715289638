import React from 'react';
import s from './Privacy.module.css';
import { Button } from '../components/button/Button';
import { TopNav } from '../components/top-nav/TopNav';
import { PageContainer } from '../components/page-container/PageContainer';

const Privacy: React.FC<any> = () => {
  return (
    <PageContainer>
      {' '}
      <TopNav />
      <div className={s.container}>
        <h2>Pardus býður þig velkomin/n!</h2>
        <p>
          Notendaskilmálar þessir eru mikilvægir og kveða þeir á um reglur sem
          gilda um samband þitt og Pardus og hafa þannig áhrif á lagaleg
          réttindi þín og skyldur. Við biðjum þig því vinsamlegast, áður en þú
          nýtir þjónustu Pardus, að lesa vandlega yfir skilmála þessa.
        </p>
        <h2 className={'mt-4'}>
          <strong>NOTENDASKILMÁLAR</strong>
        </h2>
        <p>
          Pardus (hér eftir „Pardus“, „við“ eða „okkar“) býður upp á þjónustu er
          tengir saman framleiðendur hljóðbóka, rafbóka eða annars sambærilegs
          efnis við neytendur. Með því að stofna reikning, hefja fría
          reynsluáskrift og/eða gerast áskrifandi að tiltekinni áskriftarleið
          hjá Pardus, með því að kaupa og/eða nota gjafabréf, með því að hala
          niður, fá aðgang að og/eða nota Pardus smáforritið eða heimasíðu
          Pardus, með því að nota viðbótarþjónustu eða vöru frá Pardus, eða með
          öðrum hætti fá aðgang að eða nota efnið, eða einhverja eiginleika eða
          virkni þjónustu Pardus, eftir því sem við á, ert þú að gera bindandi
          samning við Pardus og samþykkir að fara eftir þessum notendaskilmálum.
          Jafnframt viðurkennir þú og samþykkir að Pardus vinni
          persónuupplýsingar um þig í samræmi við persónuverndarstefnu Pardus.
          Notendaskilmálar þessir eru bindandi fyrir neytanda á hverjum tíma og
          gangar framar fyrri samþykktum skilmálum og skilyrðum er neytandi kann
          að hafa samþykkt. Það er forsenda þess að þú, neytandi, fáir aðgang að
          þjónustu Pardus að skilmálar þessir séu samþykktir. Með því að ganga
          að þessum skilmálum samþykkir þú og viðurkennir að Pardus megi segja
          upp samningnum ellegar loka hvenær sem er aðgangi þínum að þjónustunni
          tímabundið ef þú uppfyllir ekki einhver ákvæði þessara skilmála.
          Samþykkir þú ekki skilmálana (eins og þeir eru skilgreindir hér að
          neðan) er þér óheimilt að nota þjónustuna, fá aðgang að og/eða nýta
          þér efni Pardus. Notendaskilmálar þessir, persónuverndarstefna Pardus
          og hvers kyns viðbótarskilmálar og viðbótar skilyrði Pardus eru hér
          eftir í sameiningu nefnd „skilmálarnir“. Gildandi útgáfu af
          skilmálunum má ávallt finna og nálgast á heimasíðu Pardus.
        </p>
        <h2 className={'mt-4'}>
          <strong>Skilyrði fyrir notkun þjónustu Pardus</strong>
        </h2>
        <p>
          Til þess að undirgangast skilmálana og gerast áskrifandi að þjónustu
          Pardus verður þú að:
        </p>
        <ul>
          <li>
            hafa heimild og lagalegan rétt til að undirgangast samning þennan
            samkvæmt lögum þess lands þar sem þjónustan er gerð þér aðgengileg;
            og
          </li>
          <li>samþykkja að vera bundinn af skilmálunum.</li>
        </ul>
        <p>
          Þá verður þú jafnframt að veita Pardus réttar upplýsingar um þig þegar
          þú stofnar reikning hjá Pardus auk þess sem þú verður að veita réttar
          tengiliðaupplýsingar og velja gildan greiðslumáta. Það er skilyrði
          fyrir aðgengi að og notkun þjónustunnar að slíkar upplýsingar séu
          veittar og að skilmálarnir séu samþykktir.
        </p>
        <h2 className={'mt-4'}>
          <strong>Áskrift að þjónustu Pardus</strong>
        </h2>
        <p>
          Pardus er vettvangur þar sem eigendur hlaðvarpa og annars konar hljóð-
          og myndefnis bjóða upp á þjónustur sínar í gegnum Pardus hugbúnaðinn.
          Pardus áskilur sér rétt til þess að bjóða upp á þjónustu sína með
          mismunandi áskriftarleiðum frá mismunandi efnisframleiðendum, t.d. en
          þó ekki takmarkað við: kynningarleiðir, leiðir sem veittar eru af (eða
          tengdar eru) þriðju aðilum, leiðir sem grundvallast á fjölda samtímis
          streyma sem í boði eru, eða leiðir sem grundvallast á takmörkunum á
          því efni sem veitt er. Pardus áskilur sér rétt til þess að bæta við
          nýjum áskriftarleiðum, fjarlægja núverandi leiðir eða breyta hvers
          kyns eiginleikum eða virkni áskriftaleiða frá einum tíma til annars.
          Upplýsingar um verð og þær áskriftarleiðir sem í boði eru á hverjum
          tíma má ávallt finna á heimasíðu Pardus.
        </p>
        <h2 className={'mt-4'}>
          <strong>Verð og greiðslur</strong>
        </h2>
        <p>
          Pardus starfar með ótengdum greiðsluþjónustum sem allar þínar
          greiðslur verða framkvæmdar í gegnum áður en þær eru gerðar upp við
          Pardus. Þegar að notandi kaupir áskrift af hlaðvarpi í gegnum Pardus,
          þá tekur Pardus við greiðslu fyrir hönd hlaðvarpseiganda og greiðir
          áfram að frádreginni þóknun fyrir þjónustu Pardus við
          hlaðvarpseiganda.
        </p>
        <p>
          Greitt er fyrirfram fyrir áskriftarleiðir Pardus. Verð fyrir áskriftir
          og aðrar þjónustur eru birt á vefsíðu Pardus. Verðlagning á þjónustum
          er alfarið stjórnað af eigendum hlaðvarpanna sem bjóða upp á sínar
          áskriftir í gegnum Pardus. Það er á ábyrgð hlaðvarpseiganda að leggja
          mat á það hvort að áskriftverð þeirrar þjónustu sem hann veitir eigi
          að innihalda VSK. Við mælum með að eigendur hlaðvarpa leiti sér
          ráðgjafar hjá sérfræðingum varðandi skattatengd málefni.
        </p>
        <p>
          Hægt er að framkvæma greiðslu með kredit-/debetkorti eða aftir atvikum
          með öðrum greiðslumáta sem Pardus upplýsir þig um á hverjum tíma. Að
          því er varðar kreditkort getur Pardus synjað um eða hafnað
          kreditkortum sem ekki eru gefin út í því landi sem þjónustan er boðin
          þér. Pardus áskilur sér einnig rétt til þess, á hverjum tíma og upp á
          sitt eindæmi, að hafna tilteknum tegundum kreditkorta.
        </p>

        <p>
          Verð kunna að vera mismunandi eftir þeim greiðslumáta sem notaður er.
          Ef verð eru mismunandi þá mun Pardus miðla þeim upplýsingum á vefsíðu
          sinni í tengslum við almennar upplýsingar um verð. Verðin innihalda
          ekki kostnað vegna gagnaflutninga eða gjöld sem veitandi net- eða
          fjarskiptaþjónustu notandans kann að fara fram á í samræmi við samning
          notandans við slíkan þjónustuveitanda.
        </p>
        <p>
          Komi til vanskila eða greiðsludráttar á greiðslum áskilur Pardus sér
          rétt til þess að loka á aðgang þinn að þjónustunni tímabundið eða
          varanlega
        </p>
        <h2 className={'mt-4'}>
          <strong>Hugverkaréttindi</strong>
        </h2>
        <p>
          Efni sem framleitt er af hlaðvarpseigendum er alfarið eign þeirra og
          ábyrgð af framleiðslu efnis er jafnframt þeirra.
        </p>
        <p>
          Þjónusta Pardus er höfundarréttarvarin eign okkar og hefur að geyma
          höfundarréttarvarða eign leyfisveitenda okkar eða leyfishafa, og öll
          vörumerki, þjónustumerki, viðskiptaheiti, vöruútlit og önnur
          hugverkaréttindi innifalin í þjónustunni eða í efninu sem stendur þér
          til boða í gegnum þjónustuna eru okkar eign eða leyfisveitenda okkar
          eða leyfishafa ellegar tengdra fyrirtækja.
        </p>
        <p>
          Nema við samþykkjum það sérstaklega með skriflegum hætti má ekki nota
          eða nýta neinn þátt þjónustunnar eða efnisins, sem hún inniheldur, á
          neinn annan hátt en sem hluta af þjónustunni sem þér er veitt samkvæmt
          skilmálunum. Jafnvel þótt þú eigir hið áþreifanlega tæki, sem þú notar
          til þess að nýta þér þjónustuna höldum við fullu og algjöru
          eignarhaldi á þjónustunni og öllum hugverkaréttindum sem í henni
          felast. Við hvorki framseljum eignarrétt eða eignarhald á nokkrum
          hluta þjónustunnar til þín né framseljum við til þín nokkurn
          eignarrétt eða eignarhald á nokkrum hluta þess efnis sem aðgengilegt
          er í gegnum þjónustuna. Ekkert það sem boðið er upp á af nokkru
          vöruheiti í eign eða undir nytjaleyfi Pardus skal túlka sem svo, að
          það veiti, beint eða óbeint, nokkurt leyfi eða rétt til þess að nota
          nokkurt vörumerki, sem birt er í tengslum við eða sem hluti af
          þjónustunni sem þér er veitt.
        </p>
        <h2 className={'mt-4'}>
          <strong>Leyfi til nýtingar á efni og hugbúnaði</strong>
        </h2>

        <p>
          Það efni sem þér er aðgengilegt í gegnum þjónustuna kann að vera
          mismunandi frá einum tíma til annars og mismunandi milli ólíkra landa
          og svæða þar sem þjónustan er aðgengileg. Efnið kann einnig að vera
          mismunandi eftir staðsetningu þinni þegar þú nálgast þjónustuna.
          Pardus ber enga ábyrgð á því að þjónustan eða efnið sem aðgengilegt er
          í gegnum þjónustuna verði tiltækt til notkunar í öðru landi en því sem
          þú hefur aðsetur og þar sem þjónustan er gerð þér aðgengileg. Pardus
          ber engin skylda til þess að tilkynna þér um breytingar sem kunna að
          verða á því efni sem í boði er.
        </p>

        <p>
          Þjónustan er stillt til þess að gera notendum kleift að nota
          þjónustuhugbúnaðinn, efni þjónustunnar, sýndarefni eða annað efni, sem
          er í okkar eigu eða með nytjaleyfi okkar. Við veitum þér hér með
          takmarkað, afturkallanlegt leyfi, sem felur ekki í sér einkarétt og er
          ekki framseljanlegt, til þess að fá aðgang að og nota
          þjónustuhugbúnaðinn, efni þjónustunnar, sýndarefni eða annað efni til
          eigin nota einvörðungu en ekki í atvinnuskyni.
        </p>

        <p>
          Þú staðfestir, ábyrgist og samþykkir að þú, eða hver annar sem þú
          veitir aðgang að þjónustunni í gegnum reikninginn þinn, muni ekki
          afrita, endurgera, tvöfalda, breyta, skapa afleidd verk, birta, gefa
          út, dreifa, miðla, útvarpa, senda út, selja, leigja, lána, framselja,
          dreifa eða á annan hátt nýta í nokkrum tilgangi (í atvinnuskyni eða
          öðru) nokkuð efni og/eða hluta af þjónustunni eða þjónustuna í heild
          til þriðja aðila (þar með er talin, án takmarkana, birting og dreifing
          efnisins á vefsíðu þriðja aðila) án þess að skýrt skriflegt
          fyrirframleyfi Pardus liggi fyrir eða að slíkt sé sérstaklega heimilað
          á grundvelli gildandi ófrávíkjanlegra laga.
        </p>

        <p>
          Þú jafnframt staðfestir, ábyrgist og samþykkir að þú, eða hver annar
          sem þú veitir aðgang að þjónustunni í gegnum reikninginn þinn muni
          ekki; (1) endurdreifa, sniðganga eða gera nokkra afritunarvörn eða
          stafræna réttindavarnatækni, sem notuð er í þjónustunni, óvirka; (2)
          bakþýða, vendismíða, taka í sundur eða á annan hátt færa hvers kyns
          þjónustu á form sem er læsilegt mönnum; (3) fjarlægja hvers kyns
          auðkennisupplýsingar, upplýsingar um höfundarrétt eða aðrar
          réttindaupplýsingar; eða (4) ná í eða nota þjónustuna á ólöglegan eða
          óleyfilegan hátt eða á einhvern þann máta sem gefur til kynna tengsl
          við vörur okkar, þjónustu eða vörumerki.
        </p>
        <h2 className={'mt-4'}>
          <strong>Efni frá þriðju aðilum</strong>
        </h2>

        <p>
          Pardus veitir í gegnum þjónustu sína aðgang að efni sem kann að eiga
          uppruna sinn hjá þriðju aðilum. Birting efnis frá þriðju aðilum sem er
          aðgengilegt í gegnum þjónustu Pardus, felur ekki í sér stuðning af
          hálfu Pardus og/eða tengdra fyrirtækja þess við álit eða skoðanir sem
          slíkir þriðju aðilar setja fram. Pardus staðfestir ekki eða ábyrgist
          nákvæmni, gildi, heilleika eða gæði þess efnis sem stafar frá þriðju
          aðilum. Ennfremur er Pardus ekki ábyrgt fyrir gæðum eða afhendingu
          vöru eða þjónustu sem er boðin, sótt, fengin í gegnum eða auglýst á
          vefsvæðum/samskiptamiðlasíðum slíkra þriðju aðila. Sem slíkt mun
          Pardus ekki vera ábyrgt fyrir efni sem boðið er upp á á
          vefsíðum/samskiptamiðlasíðum þriðju aðila og Pardus mun, enn fremur,
          ekki undir neinum kringumstæðum vera ábyrgt fyrir nokkru beinu eða
          óbeinu tapi eða öðru tjóni, hvort sem orsökin er vanræksla,
          samningsbrot, ærumeiðing, brot á höfundarrétti eða öðrum
          hugverkarétti, sem kemur til vegna birtingar, dreifingar eða nýtingar
          hvers kyns upplýsinga eða efnis sem er að finna á
          vefsvæðum/samskiptamiðlasíðum þessara þriðju aðila sem tenglar vísa
          til. Skoðanir þriðu aðila eru þeirra eigin og endurspegla ekki
          skoðanir Pardus eða tengdra fyrirtækja.
        </p>
        <h2 className={'mt-4'}>
          <strong>Uppsögn og gildistími þjónustu</strong>
        </h2>
        <p>
          Þú getur sagt upp áskrift að einstöku efni hvenær sem er en hefur samt
          aðgengi að fyrirfram keyptu efni þar til áskriftinni líkur.
        </p>

        <p>
          Pardus kann að bjóða upp á áskriftarleið sem gildir í tiltekinn tíma.
          Um leið og þú hefur skráð reikning hjá Pardus og skráð þig fyrir
          einhverri þeirri tímabundnu áskriftarleið eða prófað fría
          reynsluáskrift mun áskriftin þín hjá Pardus halda áfram frá mánuði til
          mánaðar þangað til henni er sagt upp af þér eða Pardus. Nema að þú
          segir upp áskriftinni þinni veitir þú okkur því heimild til þess að
          krefja þig um áskriftargjald næsta mánaðar í samræmi við skilmálana og
          samkvæmt þeirri greiðsluaðferð sem þú hefur valið og skráð hjá Pardus
          þegar þú skráðir þig fyrir þjónustunni, eða samkvæmt síðari fyrirmælum
          þínum til okkar.
        </p>

        <p>
          Til þess að segja upp þjónustunni áður en nýjum mánuði er bætt við
          áskriftartímabilið þitt verður þú að segja upp þjónustunni eigi síðar
          en daginn áður en nýr áskriftarmánuður hefst; að öðrum kosti mun
          áskriftin gilda og vera gjaldfærð hjá þér fyrir mánuð í viðbót áður en
          henni er lokað.
        </p>

        <p>
          Til viðbótar við þá möguleika til uppsagnar þjónustu sem fjallað er um
          í skilmálum þessum hefur Pardus einhliða rétt til þess að segja upp
          eða takmarka notkun þína á þjónustunni þegar í stað ef Pardus hefur
          ástæðu til þess að ætla að þú (eða einhver sem þú hefur veitt aðgang
          að þjónustunni) hafir brotið gegn skilmálunum eða gildandi lögum,
          reglum og reglugerðum. Þetta á einnig við ef þú á annan hátt notar
          þjónustuna á sviksamlegan hátt eða á þann veg að það geti valdið
          Pardus eða þriðja aðila tjóni.
        </p>

        <h2 className={'mt-4'}>
          <strong>Réttindi og skyldur Pardus</strong>
        </h2>
        <p>
          Sem hluti af því að veita þjónustuna kann Pardus að hafa samband við
          þig beint í gegnum hvern þann samskiptamáta sem mögulegur er á hverjum
          tíma, s.s. en ekki takmarkað við bréfleiðis, í gegnum samsfélagsmiðla,
          í gegnum síma, með smáskilaboðum, myndskilaboðum, tölvupósti eða beint
          í gegnum þjónustuna í þeim tilgangi að veita upplýsingar varðandi
          virkni (þ.m.t. að senda áminningu um gildistíma kreditkorts) og um
          efni þjónustunnar. Þar sem við á, kann Pardus einnig, nema þú hafir
          frábeðið þér slík samskipti, að hafa samband við þig bréfleiðis, í
          gegnum síma, með smáskilaboðum, myndskilaboðum, tölvupósti eða beint í
          gegnum þjónustuna varðandi kynningar eða svipaða starfsemi, vörur og
          viðburði sem tengjast þjónustunni.
        </p>

        <p>
          Pardus ber ekki ábyrgð á truflunum á farsímanetum eða á þjónustu
          netþjónustuaðila.
        </p>

        <p>
          Þjónustan er tiltæk allan sólarhringinn, sjö (7) daga vikunnar. Þó
          ábyrgist Pardus ekki að þjónustan verði ávallt bilana- eða hnökralaus.
          Ef upp koma bilanir eða truflanir sem hafa áhrif á þjónustuna skal
          Pardus fá tækifæri til þess að lagfæra þær án þess að það teljist vera
          samningsbrot, þ.e. brot á skilmálunum. Pardus hefur einnig rétt, innan
          skynsamlegra marka, til þess að loka þjónustunni, t.d. vegna
          uppfærslna eða viðhalds.
        </p>

        <p>
          Pardus hefur rétt, að öllu eða nokkru leyti, til þess að framselja
          réttindi sín og skyldur samkvæmt skilmálunum til þriðju aðila. Pardus
          hefur einnig rétt til þess að ráða undirverktaka til þess að sinna
          skyldum sínum samkvæmt skilmálunum. Slíkar breytingar, sem kunna að
          hafa áhrif á vinnslu persónuupplýsinga þinna, skulu eiga sér stað í
          samræmi við persónuverndarstefnu Pardus.
        </p>

        <p>
          Pardus getur upp á sitt eindæmi gert breytingar á skilmálunum. Þegar
          Pardus gerir verulegar breytingar á skilmálunum þannig að það hafi
          skaðleg áhrif þig mun þér verða greint frá slíku.
        </p>
        <h2 className={'mt-4'}>
          <strong>Réttindi og skyldur þínar</strong>
        </h2>
        <p>
          Nema þér sé tilkynnt sérstaklega um annað þá er þér sem áskrifanda
          heimilt að nota efni þjónustunnar til einkanota einvörðungu og ekki í
          atvinnuskyni. Aðgangsupplýsingar þínar (þ.m.t. en ekki takmarkað við
          innskráningarupplýsingar) eru einkaupplýsingar og óheimilt er að miðla
          þeim til annarra.
        </p>

        <p>
          Þú samþykkir að sniðganga ekki eða reyna að sniðganga þær tæknilegu
          takmarkanir eða aðrar takmarkanir sem til staðar eru til þess að koma
          í veg fyrir afritun efnis í þjónustunni og að afrita ekki, hvorki að
          öllu leyti eða að hluta, efni þjónustunnar, jafnvel ekki til eigin
          nota, nema og að því marki sem slíkt er heimilt samkvæmt
          ófrávíkjanlegum lögum.
        </p>

        <p>
          Þú viðurkennir og samþykkir að aðgengi og notkun þín á þjónustunni
          skuli vera í samræmi við skilmálana. Þú viðurkennir og samþykkir
          jafnframt hið sama varðandi alla þá sem þú veitir aðgang að
          þjónustunni í gegnum reikninginn þinn. Þér er aðeins heimilt að veita
          öðrum notenda aðgang í samræmi við skilmálana.
        </p>

        <p>
          Þú ert ábyrg/ur fyrir því að tryggja að upplýsingar, sem veittar eru
          við stofnun reiknings, séu réttar og að hvers kyns persónuupplýsingar,
          sem veittar eru Pardus, svo sem tölvupóstfang þitt, séu réttar. Þú er
          ábyrg/ur fyrir því að láta Pardus vita um hvers kyns breytingar á
          hinum veittu upplýsingum, sér í lagi tölvupóstfangi þínu. Líta skal
          þannig á að öll tölvupóstskeyti, sem Pardus sendir á tölvupóstfang
          þitt, hafi verið móttekin af þér innan tveggja (2) daga frá því að
          tölvupóstskeytið var sent.
        </p>

        <p>
          Þú ert ábyrg/ur fyrir því að halda forræði yfir reikningi þínum, fyrir
          því að koma í veg fyrir óviðkomandi aðgang að þjónustunni og samþykkir
          að gefa engum öðrum upp aðgangsorðið þitt eða aðrar persónuupplýsingar
          tengdar við reikning þinn svo lengi sem þú ert áskrifandi að
          þjónustunni.
        </p>

        <p>
          Í hvers kyns umsögnum um efni þeirrar þónustu sem Pardus veitir, sem
          áskrifandi ritar, ber áskrifandi ábyrgð á að tryggja að skoðanir
          áskrifanda séu settar fram í samræmi við gildandi lög og af virðingu
          við aðra og að áskrifandinn noti ekki orð eða orðalag sem túlka megi
          svo að það mismuni eða sé óþarflega móðgandi eða hneykslanlegt á
          samræðuvettvangi sem kann að vera tengdur Pardus. Það er ennfremur
          ábyrgð áskrifandans að tryggja að áskrifandinn birti ekki, noti eða
          deili hugverkum í eigu þriðja aðila eða birti, noti eða deili efni eða
          athugasemdum sem eru eða kunni líklega að vera talin ólögleg, t.d.
          ærumeiðandi efni, efni sem sýnir eða gefur í skyn kynferðislega
          misnotkun eða hatursáróður á netinu.
        </p>

        <p>
          Áskrifandinn hefur engan rétt til þess að framselja réttindi sín og
          skyldur samkvæmt skilmálunum nema áskrifandinn hafi fengið skriflega
          heimild Pardus til þess.
        </p>

        <p>
          Áskrifandinn skal ekki nota þjónustuna á neinn þann hátt sem veldur
          eða er líklegur til þess að valda því að þjónustan verði fyrir
          truflunum, tjóni eða skerðist að nokkru leyti. Þú skilur að þú, en
          ekki Pardus, berð ábyrgð á öllum rafrænum samskiptum og efni sem sent
          er úr tækinu þínu til okkar og að þú verður að nota þjónustuna, tækið
          þitt og vefsvæðið okkar og hvern þann vettvang, sem Pardus lætur í té
          bæði innan sem utan þjónustunnar, á viðeigandi átt og í löglegum
          tilgangi einvörðungu. Þar af leiðandi samþykkir þú að nota vefsvæðið
          ekki í nokkrum sviksamlegum tilgangi eða í tengslum við glæpsamlegt
          athæfi eða aðra brotastarfsemi, eða að senda, nota eða endurnota
          nokkurt efni sem tilheyrir þér ekki eða er ólöglegt, særandi (þar með
          talið en ekki einskorðað við efni sem er kynferðislega opinskátt eða
          sem ýtir undir kynþáttahyggju, hatur eða líkamsmeiðingar), blekkjandi,
          villandi, svívirðandi, ósiðlegt, áreitandi, niðrandi, ærumeiðandi,
          dónalegt, klámfengið, eða brýtur gegn höfundarrétti, vörumerki,
          trúnaði, friðhelgi eða öðrum upplýsingum sem á er einkaréttur,
          einkaleyfisrétti, ellegar skaðar þriðju aðila að öðru leyti.
        </p>

        <p>
          Ef þjónustan eða einstakir hlutar hennar reynast ófullnægjandi eða
          gallaðir ert þú hvattur/hvött til þess að hafa samband við Pardus með
          því að nota viðeigandi form á heimasíðu Pardus, með því að senda
          tölvupóst úr tölvupóstfanginu sem skráð er á reikninginn þinn eða með
          því að nota aðrar leiðir til þess að láta okkur vita eða leggja fram
          kvörtun.
        </p>

        <p>
          Áskrifandinn viðurkennir að þjónustan inniheldur efni sem er ekki
          viðeigandi fyrir eða við hæfi ólögráða barna. Þess vegna samþykkir þú
          að láta ólögráða börn ekki fá aðgang að þjónustunni nema þau séu undir
          þinni umsjón.
        </p>
        <h2 className={'mt-4'}>
          <strong>Samningsaðili</strong>
        </h2>
        <p>
          Þjónustan er veitt og gerð þér aðgengileg samkvæmt skilmálum P4rdus
          ehf., einkahlutafélag sem stofnað er á Íslandi með kennitölu
          611098-2009 og heimilisfang að Skólavörðustíg 24, 101 Reykjavík. Til
          þess að hafa samband við okkur eða þjónustudeild okkar, vinsamlegast
          sendu tölvupóst á netfangið{' '}
          <a
            href="mailto:pardus@pardus.is"
            className={'underline cursor-pointer'}
          >
            pardus@pardus.is
          </a>
          .
        </p>
        <h2 className={'mt-4'}>
          <strong>VAFRAKÖKUR</strong>
        </h2>
        <p>
          Pardus notar vafrakökur til að tryggja góða upplifun fyrir notendur.
          Vafrakaka (e. Cookie) er lítil textaskrá sem vafrinn vistar í tölvuna
          þína eða snjalltæki þegar þú heimsækir þennan vef. Upplýsingarnar í
          kökunni má nota til að fylgjast með vafri notenda á vefsvæðinu og þær
          upplýsingar til að bæta vefinn, þjónustuna o.fl. Með því að samþykkja
          notkun á vafrakökum heimilar þú vefnum m.a.:
        </p>
        <ul>
          <li>
            að bera kennsl á notendur sem hafa komið áður og sníða þá leit,
            þjónustu, ofl. í samræmi við fyrri notkun og stillingar
          </li>
          <li>
            að safna saman tölfræðilegum upplýsingum sem notaðar eru til gefa
            innsýn til að bæta vefsvæðið og þjónustuna
          </li>
          <li>að birta notendum auglýsingar</li>
          <li>
            að safna og senda tilkynningar um fjölda notenda og umferð um vefinn
          </li>
        </ul>
        <p>
          Pardus notar Google Analytics til að til vefmælinga. Upplýsingar sem
          Google Analytics safnar eru ekki persónugreinanlegar upplýsingar. Við
          notum Facebook og Google Ads til að mögulega birta auglýsingar til
          notenda sem heimsótt hafa vefsvæðið en það er gert án þess að notast
          við persónugreinanlegar upplýsingar.
        </p>
        <p>
          Ef þú vilt ekki heimila slíkt getur þú slökkt á þeim í stillingum í
          vafranum. Góðar upplýsingar um stillingar á vafrakökum er að finna
          hér: allaboutcookies.org
        </p>
        <h2 className={'mt-4'}>
          <strong>Annað</strong>
        </h2>
        <p>
          Þjónustan er veitt þér einvörðungu í þeim tilgangi að njóta
          afþreyingar, upplýsinga og í öðrum svipuðum tilgangi sem ekki er í
          atvinnuskyni.
        </p>

        <p>
          Skilmálar þessir heyra undir og skulu túlkaðir á grundvelli íslenskra
          laga, að frátöldum reglum um lagaskil. Skilmálarnir takmarka þó ekki
          réttindi þín til neytendaverndar, sem þú kannt að hafa á grundvelli
          löggjafar þess lands, sem þú hefur aðsetur í, ef þau eru ólík því sem
          að ofan greinir.
        </p>

        <p>
          Komi upp ágreiningur á milli Pardus og áskrifandans, skulu aðilar
          byrja á því að reyna að útkljá ágreininginn með samkomulagi. Ef aðilar
          geta ekki komist að samkomulagi má beina málinu til Neytendastofu,
          Borgartúni 21, 105 Reykjavík, https://www.neytendastofa.is/. Að öðrum
          kosti skal leysa ágreininginn fyrir íslenskum dómstólum.
        </p>

        <p>
          Ef þú ert óánægð/ur með þjónustuna, efnið, sem hún veitir aðgang að,
          eða með skilmálana er eina mögulega lausn þín sú að hætta að sækja eða
          nota þjónustuna
        </p>

        <p>
          Til að fá frekari upplýsingar um þjónustuna eða ef þú þarfnast
          aðstoðar með einhverja eiginleika eða stillingar, ellegar reikninginn
          þinn, hafðu vinsamlegast samband við{' '}
          <a href="mailto:pardus@pardus.is">pardus@pardus.is</a>
        </p>
        <h2 className={'mt-4'}>P4rdus ehf.</h2>
      </div>
    </PageContainer>
  );
};

export default Privacy;
