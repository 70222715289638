import React, { useEffect, useState } from 'react';
import GeneralModal from './GeneralModal';
import { useDispatch } from 'react-redux';
import { Button } from '../button/Button';
import { hideModal } from '../../redux/actions/modal';
import SubscriptionBusiness from '../../business/SubscriptionBusiness';
import { useFirestore } from 'react-redux-firebase';
import { Spinner } from '../spinner/Spinner';
import { format } from 'date-fns';

type RandomSubT = {
  name?: string;
  ssn?: string;
  createdAt: string;
  endsAt: string;
  email: string;
};
export const RandomSubscriptionModal = ({
  podcastId,
}: {
  podcastId: string;
}) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const [isLoading, setIsLoading] = useState(false);
  const [sub, setSub] = useState<RandomSubT | null>(null);
  const subscriptionBusiness = new SubscriptionBusiness(firestore);
  const getData = async () => {
    setIsLoading(true);
    const subData = await subscriptionBusiness.getRandomSubscription(podcastId);
    setSub(subData as unknown as RandomSubT);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <GeneralModal disableOutsideClick>
      {isLoading && (
        <div
          className={
            'min-h-[364px] flex justify-center items-center'
          }
        >
          <Spinner />
        </div>
      )}
      {sub && (
        <div className={'p-6 space-y-4'}>
          <div className={'font-bold text-xl text-center'}>
            {sub.name}
          </div>
          <div className={'space-y-2'}>
            <div
              className={'p-2 bg-gray-100 border border-gray-200'}
            >
              <strong>Netfang:</strong> {sub.email}
            </div>
            <div
              className={'p-2 bg-gray-100 border border-gray-200'}
            >
              <strong>Kennitala:</strong> {sub.ssn}
            </div>
            <div
              className={'p-2 bg-gray-100 border border-gray-200'}
            >
              <strong>Áskrift stofnuð:</strong>{' '}
              {format(sub.createdAt, 'dd. MMM yyyy HH:mm:ss')}
            </div>
            <div
              className={'p-2 bg-gray-100 border border-gray-200'}
            >
              <strong>Áskrift endar:</strong>{' '}
              {format(sub.endsAt, 'dd. MMM yyyy HH:mm:ss')}
            </div>
          </div>
          <div>
            <Button onClick={() => dispatch(hideModal())} className={'black'}>
              Loka
            </Button>
          </div>
        </div>
      )}
    </GeneralModal>
  );
};
