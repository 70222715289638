import React, {useState} from 'react';
import s from './ConfirmModal.module.css';
import { connect, useDispatch } from 'react-redux';
import GeneralModal from './GeneralModal';
import classNames from 'classnames';
import { Button } from '../button/Button';
import { hideModal } from '../../redux/actions/modal';

export type ConfirmModalProps = {
  title: string;
  actionText: string;
  color?: string;
  body: string;
  action: any;
  actionTextColor?: string;
};
const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  actionText,
  color,
  action,
  body,
  actionTextColor
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const performAction = async () => {
    setIsLoading(true);
    await action();
    setIsLoading(false);
    dispatch(hideModal());
  };
  return (
    <GeneralModal disableOutsideClick>
      <div
        className={classNames({ [s.title]: true, [s[color || 'red']]: true })}
      >
        {title}
      </div>
      <div className={s.content}>{body}</div>
      <div className={s.actions}>
        <div style={{ marginBottom: '30px' }}>
          <Button className={'white'} onClick={() => dispatch(hideModal())}>
            Hætta við
          </Button>
          <Button disabled={isLoading} className={'white'} onClick={performAction}>
            <span className={classNames({ [s[actionTextColor || 'redText']]: true })}>
              {isLoading && 'Hinkraðu...'}
              {!isLoading && (actionText ? actionText : 'Já')}
            </span>
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};

export default connect()(ConfirmModal);
