import { useMutation } from '@tanstack/react-query';
import z from 'zod';
import { useFirebase } from 'react-redux-firebase';
import { useTypedSelector } from '@/redux/reducers';
import { uploadAttachment } from '@/utils/utilities';

const uploadAttachmentSchema = z.object({
  file: z.instanceof(File),
  video: z.boolean().optional(),
  setUploadProgress: z.function().optional(),
});

export function useUploadAttachment() {
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  return useMutation({
    mutationFn: async ({
      file,
      video,
      setUploadProgress,
    }: z.infer<typeof uploadAttachmentSchema>) =>
      uploadAttachment(file, firebase, auth?.uid, video, setUploadProgress),
  });
}
