import z from 'zod';
import { dateStringSchema } from '@/utils/zod-utils';
import { NON_EMPTY_ERROR } from '@/components/react-form-utils/common';

const episodeSchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty(),
  img: z.string().nonempty(),
  description: z.string().nonempty(),
  url: z.string().nonempty(),
  isOpen: z.boolean().default(false),
});

const emailSchema = z.object({
  subject: z.string(),
  content: z.string(),
});

const feedSchema = z.object({
  content: z.string(),
  name: z.string().optional(),
  allowComments: z.boolean().default(false),
  url: z
    .object({
      src: z.string(),
      type: z.enum(['VIDEO', 'IMAGE']),
    })
    .optional(),
});

export const scheduleEpisodeFormSchema = z.object({
  episode: episodeSchema,
  feed: feedSchema
    .merge(z.object({ enabled: z.boolean() }))
    .refine((val) => !(val.enabled && !val.content), {
      path: ['content'],
      message: NON_EMPTY_ERROR,
    }),
  email: emailSchema
    .merge(z.object({ enabled: z.boolean() }))
    .refine(
      (val) => {
        return !(val.enabled && !val.subject);
      },
      { path: ['subject'], message: NON_EMPTY_ERROR },
    )
    .refine(
      (val) => {
        return !(val.enabled && !val.content);
      },
      { path: ['content'], message: NON_EMPTY_ERROR },
    ),
  schedule: z
    .object({
      publishAt: dateStringSchema,
      enabled: z.boolean(),
    })
    .refine((val) => !(val.enabled && !val.publishAt), {
      path: ['publishAt'],
      message: NON_EMPTY_ERROR,
    }),
});

export const scheduleSchema = z.discriminatedUnion('type', [
  z.object({
    id: z.string().optional(),
    type: z.literal('EPISODE'),
    publishAt: dateStringSchema,
    episode: episodeSchema,
    feed: feedSchema.optional(),
    email: emailSchema.optional(),
  }),
  z.object({
    id: z.string().optional(),
    type: z.literal('EMAIL'),
    publishAt: dateStringSchema,
    email: emailSchema,
  }),
  z.object({
    id: z.string().optional(),
    type: z.literal('FEED'),
    publishAt: dateStringSchema,
    feed: feedSchema,
  }),
]);

export const scheduleEmailFormSchema = z.object({
  subject: z.string().nonempty(),
  content: z.string().nonempty(),
  publishAt: dateStringSchema
});