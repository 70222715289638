import type { PropsWithChildren } from 'react';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';

export const RssContainer = () => {
  return (
    <div
      className={
        'p-8 border space-y-4 border-[#cecece] rounded-md mb-4'
      }
    >
      <div className={'text-2xl font-bold'}>Hlustaðu í hlaðvarpsappi</div>
      <div>
        RSS hlekkir eru tengdir við þinn aðgang og gera þér kleift að hlusta á
        áskriftarþætti í nánast hvaða hlaðvarpsappi sem er.
      </div>
      <Link to={'/rss'} className={'inline-block'}>
        <Button>Tengjast appi</Button>
      </Link>
    </div>
  );
};
