import React from 'react';
import withPodcast from '../utils/withPodcast';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
  isEmpty,
  isLoaded,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { Episode, ManagePodcastTab, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { useTypedSelector } from '../redux/reducers';
import { NoItems } from '../components/no-items/NoItems';
import { ManageEpisodesLists } from '../components/manage-episodes-list/ManageEpisodesList';
import EpisodeBusiness from '../business/EpisodeBusiness';
import { Spinner } from '../components/spinner/Spinner';
import { PageContainer } from '../components/page-container/PageContainer';
import { Button } from '@/components/ui/button';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { EpisodesSchedulesList } from '@/components-v2/EpisodesSchedulesList';
import { useHistory } from 'react-router';

const ManagePodcastUploads: React.FC<any> = (props) => {
  const params = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const {
    firestore,
    podcast,
    profile,
  }: {
    firebase: ExtendedFirebaseInstance;
    firestore: ExtendedFirestoreInstance;
    podcast: Podcast;
    profile: any;
  } = props;
  useFirestoreConnect({
    collection: 'episodes',
    orderBy: ['createdAt', 'desc'],
    where: profile.isAdmin
      ? [
          ['podcastId', '==', podcast.id],
          ['active', '==', true],
        ]
      : [
          ['podcastId', '==', podcast.id],
          ['userId', '==', profile.userId],
          ['active', '==', true],
        ],
  });

  const episodes = useTypedSelector(
    (state) => state.firestore.ordered.episodes,
  );

  const deleteEpisode = (episode: Episode) => {
    return async () => {
      const episodeBusiness = new EpisodeBusiness(firestore, profile?.userId);
      await episodeBusiness.update({ id: episode.id, active: false });
    };
  };

  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.UPLOADS} podcast={podcast}>
        <Tabs value={params.get('tab') || 'schedules'}>
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <Link to={`/podcasts/uploads/${podcast.id}/new`}>
              <Button>+ Nýr þáttur</Button>
            </Link>
          </div>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              value="schedules"
              onClick={() => history.push(`/podcasts/uploads/${podcast.id}`)}
            >
              Þættir í bið
            </TabsTrigger>
            <TabsTrigger
              value="published"
              onClick={() =>
                history.push(`/podcasts/uploads/${podcast.id}?tab=published`)
              }
            >
              Í birtingu
            </TabsTrigger>
          </TabsList>
          <TabsContent value="schedules">
            <EpisodesSchedulesList />
          </TabsContent>
          <TabsContent value={'published'}>
            {isLoaded(episodes) && isEmpty(episodes) ? (
              <>
                <NoItems
                  message={'Þú hefur ekki hlaðið upp neinum þætti ennþá.'}
                />
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <Link to={`/podcasts/uploads/${podcast.id}/new`}>
                    <Button>+ Nýr þáttur</Button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                {isLoaded(episodes) ? (
                  <ManageEpisodesLists
                    deleteEpisode={deleteEpisode}
                    episodes={episodes || []}
                    podcastId={podcast.id as string}
                  />
                ) : (
                  <Spinner />
                )}
              </>
            )}
          </TabsContent>
        </Tabs>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastUploads);
