import React, { useState } from 'react';
import withPodcast from '../utils/withPodcast';
import {
  ExtendedFirebaseInstance,
  ExtendedFirestoreInstance,
} from 'react-redux-firebase';
import { ManagePodcastTab, ModalType, Podcast } from '../types';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { Textarea } from '../components/textarea/Textarea';
import { TextInput } from '../components/text-input/TextInput';
import { Button } from '../components/button/Button';
import PodcastBusiness from '../business/PodcastBusiness';
import { error, success, warn } from '../utils/Notifications';
import { TogglePodcast } from '../components/toggle-podcast/TogglePodcast';
import { UploadPicture } from '../components/upload-picture/UploadPicture';
import { PageContainer } from '../components/page-container/PageContainer';
import { DateTimePickerInput } from '../components/date-time-picker-input/DateTimePickerInput';
import clientConfig from '../client-config';
import { showModal } from '../redux/actions/modal';
import { FormCheckbox } from '../components/form-fields/FormCheckbox';
const PodcastDeadline: React.FC<{
  deadline: Date | null;
  setDeadline: (deadline: Date | null) => void;
}> = ({ deadline, setDeadline }) => {
  return (
    <>
      <DateTimePickerInput
        label={'Loka á sölu þann:'}
        value={deadline}
        onChange={setDeadline}
      />
    </>
  );
};

const ManagePodcastGeneral: React.FC<any> = (props) => {
  const {
    firebase,
    firestore,
    podcast,
    profile,
  }: {
    firebase: ExtendedFirebaseInstance;
    firestore: ExtendedFirestoreInstance;
    podcast: Podcast;
    profile: any;
  } = props;
  const [hideTitle, setHideTitle] = useState<boolean>(
    podcast?.hideTitleOnDetailPage || false
  );
  const [description, setDescription] = useState<string>(
    podcast?.description || ''
  );
  const [shortDescription, setShortDescription] = useState<string>(
    podcast?.shortDescription || ''
  );
  const [subscriptionDescription, setSubscriptionDescription] =
    useState<string>(podcast?.subscriptionDescription || '');
  const [rss, setRss] = useState<string>(podcast?.rss || '');
  const [deadline, setDeadline] = useState<Date | null>(
    podcast?.deadline?.toDate() || null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const podcastBusiness = new PodcastBusiness(
    firestore,
    profile?.userId,
    profile?.email
  );
  const onSave = async (hideFeedback: boolean) => {
    if (!hideFeedback) {
      setIsLoading(true);
    }
    try {
      await podcastBusiness.update({
        id: podcast.id,
        description,
        shortDescription,
        rss,
        deadline: deadline as any,
        subscriptionDescription,
        hideTitleOnDetailPage: hideTitle,
      });
      if (!hideFeedback) {
        success('Upplýsingar hafa verið uppfærðar.');
      }
    } catch (err: any) {
      error(err.message || 'Ekki tókst að vista hlaðvarpið.');
    } finally {
      setIsLoading(false);
    }
  };
  const toggleActive = async () => {
    if (!profile.isAdmin) {
      warn('Einungis stjórnandi getur breytt þessari stillingu.');
      return;
    }
    await onSave(true);
    if (podcast.status === 'DRAFT') {
      if (!description) {
        return warn('Þú verður að skrifa lýsingu til að virkja.');
      }
      if (!podcast?.image) {
        return warn('Þú verður að setja inn mynd til að virkja.');
      }
    }
    await podcastBusiness.update({
      id: podcast.id,
      status: podcast.status === 'PUBLISHED' ? 'DRAFT' : 'PUBLISHED',
    });
  };
  const saveLogo = async (url: string) => {
    await podcastBusiness.update({ id: podcast.id, image: url });
  };
  const saveCover = async (url: string) => {
    await podcastBusiness.update({ id: podcast.id, cover: url });
  };
  const Spacer: React.FC = () => (
    <div className="mt-5 h border border-gray-200" />
  );
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.GENERAL} podcast={podcast}>
        <div className="flex justify-end mb-4">
          <TogglePodcast
            checked={podcast.status === 'PUBLISHED'}
            onToggle={toggleActive}
          />
          {podcast.slug && podcast.status === 'PUBLISHED' && (
            <div className={'p-1 pl-4'}>
              <a
                className={'underline'}
                href={`${
                  clientConfig.name === 'visir'
                    ? process.env.REACT_APP_WEB_URL_VISIR
                    : process.env.REACT_APP_WEB_URL_MAIN
                }/${podcast.slug}`}
                target={'blank'}
              >
                Skoða á vef
              </a>
            </div>
          )}
        </div>
        <TextInput
          value={rss}
          label={'RSS fyrir opna þætti'}
          placeholder={'Slóð að RSS'}
          onChange={(val) => setRss(val)}
        />
        {profile.isAdmin && (
          <div style={{ marginTop: '15px' }}>
            <PodcastDeadline deadline={deadline} setDeadline={setDeadline} />
          </div>
        )}
        <Spacer />
        <div style={{ marginTop: '15px' }}>
          <Textarea
            value={description}
            label={'Lýsing'}
            placeholder={'Skrifaðu lýsingu'}
            onChange={(val) => {
              setDescription(val);
            }}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <Textarea
            value={shortDescription}
            label={'Lýsing á forsíðu (hámark 240 stafir)'}
            placeholder={
              'Skrifaðu lýsingu sem mun birtast á forsíðunni (hámark 240 stafir)'
            }
            onChange={(val) => {
              setShortDescription(val);
            }}
            maxLength={240}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <Textarea
            value={subscriptionDescription}
            label={'Lýsing á áskrift'}
            placeholder={'Skrifaðu hvað áskriftin mun innihalda'}
            onChange={(val) => {
              setSubscriptionDescription(val);
            }}
          />
        </div>
        <Spacer />
        <div className="mt-5 flex">
          <div>
            <UploadPicture
              label={'Mynd á yfirlitssíðu'}
              url={podcast?.image}
              aspectRatio={clientConfig.name === 'visir' ? 1.55 : 1}
              save={(url) => saveLogo(url)}
            />
          </div>
          <div className="ml-5">
            <UploadPicture
              label={'Mynd á hlaðvarpssíðu'}
              aspectRatio={3.6}
              url={podcast?.cover}
              save={(url) => saveCover(url)}
              minCropBoxWidth={800}
            />
          </div>
        </div>
        {clientConfig.name === 'visir' && (
          <div className={'mt-5'}>
            <FormCheckbox
              checked={hideTitle}
              onChange={(e: any) => setHideTitle(e.target.checked)}
            >
              Fela titil á hlaðvarpssíðu
            </FormCheckbox>
          </div>
        )}
        <div style={{ marginTop: '15px', textAlign: 'right' }}>
          <Button
            disabled={isLoading || !description}
            onClick={() => onSave(false)}
          >
            {isLoading ? 'Hinkraðu...' : 'Vista'}
          </Button>
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastGeneral);
