import { JSX } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { Input as ShadCnInput } from '@/components/ui/input';
import cn from 'classnames';
import { ErrorLabel } from '@/components/react-form-utils/ErrorLabel';
import { getNestedError } from '@/components/react-form-utils/common';

export const FormInput = ({
  name,
  label,
  className,
  ...props
}: Omit<JSX.IntrinsicElements['input'], 'name'> & {
  name: string;
  label?: string;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorField = getNestedError(errors, name) as FieldError | undefined;
  return (
    <div>
      {label && (
        <div>
          <label htmlFor={name} className={'mb-2 inline-block font-bold'}>
            {label}
          </label>
        </div>
      )}
      <ShadCnInput
        id={name}
        {...register(name)}
        className={cn(
          className,
          'w-full rounded-md border p-3 placeholder-gray outline-0',
        )}
        {...props}
      />
      {errorField && <ErrorLabel>{errorField.message}</ErrorLabel>}
    </div>
  );
};
