import React, { useState } from 'react';
import { Episode } from '../types';
import { error, success } from '../utils/Notifications';
import { Redirect } from 'react-router';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { EpisodeForm } from '../components/episode-form/EpisodeForm';
import EpisodeBusiness from '../business/EpisodeBusiness';
import { PageContainer } from '../components/page-container/PageContainer';

const PodcastUploadsEdit: React.FC<any> = (props) => {
  const { podcast } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');
  const firestore = useFirestore();
  const profile: any = useTypedSelector((state) => state.firebase.profile);
  const {
    match: { params },
  } = props;
  const { episodeId } = params;

  useFirestoreConnect({
    collection: 'episodes',
    doc: episodeId,
    storeAs: 'episode' + episodeId,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['episode' + episodeId],
  );
  const episode: Episode = data ? data[0] : null;
  if (!episode) {
    return null;
  }
  const save = async (episode: Episode) => {
    setIsLoading(true);
    const episodeBusiness = new EpisodeBusiness(firestore, profile?.userId);
    try {
      await episodeBusiness.update(episode);
      setIsLoading(false);
      success('Þátturinn hefur verið uppfærður.');
      setRedirect(`/podcasts/uploads/${episode.podcastId}?tab=published`);
    } catch (err: any) {
      error(err.message || 'Ekki tókst að setja inn þátt.');
      setIsLoading(false);
    }
  };
  return (
    <PageContainer skipHeader>
      <EpisodeForm
        podcastId={episode.podcastId as string}
        episode={episode}
        save={save}
        isLoading={isLoading}
        title={'Breyta þætti'}
      />
      {redirect && <Redirect to={redirect} />}
    </PageContainer>
  );
};

export default PodcastUploadsEdit;
