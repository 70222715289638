import React, { HTMLProps } from 'react';
import cn from 'classnames';

export type TailwindContainerProps = {} & HTMLProps<HTMLDivElement>;
export const TailwindContainer: React.FC<TailwindContainerProps> = ({
  children,
  className,
  ...args
}) => {
  return (
    <div className={cn('container mx-auto px-6', className)} {...args}>
      {children}
    </div>
  );
};
