import { useFormContext } from 'react-hook-form';
import z from 'zod';
import { scheduleEmailFormSchema } from '@/schemas/schedules';
import { useRequiredParam } from '@/hooks/use-required-param';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { FormToggle } from '@/components/react-form-utils/FormToggle';
import { FormInput } from '@/components/react-form-utils/FormInput';
import { FormTextArea } from '@/components/react-form-utils/FormTextArea';
import { UploadPicture as UploadPictureV2 } from '@/components-v2/UploadPicture';
import cn from 'classnames';
import { Oval } from 'react-loader-spinner';
import { FaImage } from 'react-icons/fa';
import React from 'react';
import { FormDatePicker } from '@/components/react-form-utils/FormDatePicker';

export const NotificationsForm = ({
  title,
  isLoading,
}: {
  title: string;
  isLoading: boolean;
}) => {
  const { setValue, watch } =
    useFormContext<z.infer<typeof scheduleEmailFormSchema>>();
  return (
    <div className={'space-y-4'}>
      <div className={'flex justify-between items-center'}>
        <div className={'text-2xl font-bold'}>{title}</div>
        <div className={'space-x-2'}>
          <Button type={'submit'} disabled={isLoading}>
            Staðfesta
          </Button>
        </div>
      </div>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Tilkynning</CardTitle>
            <CardDescription>
              Þú getur skrifað tilkynningu sem verður send á alla áskrifendur.
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <FormInput name={'subject'} label={'Fyrirsögn'} />
          <FormTextArea name={'content'} label={'Efni'} />
          <UploadPictureV2
            onComplete={(url) =>
              setValue('content', `${watch('content')}${url}`)
            }
          >
            {(isPending) => (
              <>
                <span
                  className={cn(
                    'transition-colors absolute w-[calc(100%_+_16px)] h-[calc(100%_+_16px)] rounded-lg -translate-x-1/2 top-1/2 left-1/2 -translate-y-1/2 z-0',
                    {
                      'group-hover:bg-gray-200': !isPending,
                    },
                  )}
                />
                <Oval
                  visible={isPending}
                  height="28"
                  secondaryColor={'#848484'}
                  width="28"
                  color="#211147"
                  strokeWidth={3}
                  wrapperClass="absolute -translate-x-1/2 top-1/2 left-1/2 -translate-y-1/2"
                />
                <FaImage className={'z-10 relative'} />
              </>
            )}
          </UploadPictureV2>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Tímasetning</CardTitle>
            <CardDescription>Veldu tímasetningu tölvupósts.</CardDescription>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <FormDatePicker name={'publishAt'} />
        </CardContent>
      </Card>
    </div>
  );
};
