import React, { useEffect, useState } from 'react';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { FeedPreview } from '../../components/feed-preview/FeedPreview';
import { PageContainer } from '../../components/page-container/PageContainer';
import { PodcastCover } from '../../components/podcast-cover/PodcastCover';
import { PodcastDescription } from '../../components/podcast-description/PodcastDescription';
import { SubscriptionInfo } from '../../components/podcast-description/SubscriptionInfo';
import { PodcastFeed } from '../../components/podcast-feed/PodcastFeed';
import { Center } from '../../components/position/Center';
import { PostToFeed } from '../../components/post-to-feed/PostToFeed';
import { Spinner } from '../../components/spinner/Spinner';
import { useTypedSelector } from '../../redux/reducers';
import { Podcast } from '../../types';
import { call } from '../../utils/ApiManager';
import { PodcastEpisodes } from '../../components/podcast-episodes/PodcastEpisodes';
import s from './PodcastDetailVisir.module.css';
import clientConfig from '../../client-config';
import { LiveStreamBanner } from '../../components/livestream-banner/LiveStreamBanner';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { PendingFeeds } from '../../components/pending-feeds/PendingFeeds';
import { TallyPoll } from '../../components/tally-poll/TallyPoll';
import { RssContainer } from '../../components/podcast-description/RssContainer';
import { EventButton } from '../../components/event-button/EventButton';

const PodcastDetailVisir: React.FC<any> = (props) => {
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const {
    match: { params },
  } = props;
  const { slug } = params;
  useFirestoreConnect({
    collection: 'podcasts',
    where: [
      ['slug', '==', slug],
      ['status', '==', 'PUBLISHED'],
      ['client', '==', clientConfig.name],
    ],
    storeAs: 'podcast-' + slug,
  });

  const data = useTypedSelector(
    (state) => state.firestore.ordered['podcast-' + slug],
  );
  const podcast: Podcast = data ? data[0] : null;
  const isLoggedIn = auth.isLoaded && !auth.isEmpty;

  const [isLoading, setIsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const podcastId = podcast?.id || '';
  const checkForSubscription = async () => {
    setIsLoading(true);
    const res = await call('/podcasts/' + podcastId + '/checkaccess', true)();
    setHasSubscription(!!res?.hasAccess);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(podcast) || !isLoaded(podcast)) {
      return;
    }
    if (isLoggedIn) {
      checkForSubscription();
    } else {
      setIsLoading(false);
    }
    if (podcast.slug) {
      localStorage.setItem('home-path', `/${podcast.slug}`);
    }
  }, [isLoggedIn, podcastId]);

  if (!auth.isLoaded || isLoading || !isLoaded(podcast) || !podcast) {
    return (
      <PageContainer>
        <Center>
          <Spinner />
        </Center>
      </PageContainer>
    );
  }
  const isAdmin = podcast.userId === auth?.uid || profile?.isAdmin;
  const isBehindPaywall = !!podcast.subscriptionPlans?.length;
  const showLeftColumn =
    !!podcast.subscriptionDescription || isBehindPaywall || isAdmin;
  return (
    <>
      {hasSubscription && podcast.eventButton?.visible && (
        <EventButton
          url={podcast.eventButton.url}
          text={podcast.eventButton.text}
        />
      )}
      {podcast.isLiveStreaming && (
        <LiveStreamBanner
          podcastId={podcastId}
          hasSubscription={
            hasSubscription || !podcast.subscriptionPlans?.length
          }
        />
      )}
      <PageContainer>
        <Helmet>
          <title>
            {podcast.name} | {clientConfig.title}
          </title>
          <link
            rel={'canonical'}
            href={clientConfig.canonical + '/' + podcast.slug}
          />
          <meta name="description" content={podcast.description} />
        </Helmet>
        <PodcastCover podcast={podcast} />
        <div className="container px-6 pb-6 mx-auto">
          <div
            className={cn(
              'block',
              'lg:grid',
              'grid-cols-1',
              'gap-10',
              {
                ['lg:grid-cols-4']: showLeftColumn,
                ['lg:grid-cols-3']: !showLeftColumn,
              },
            )}
          >
            <div className={cn({ ['hidden']: !showLeftColumn })}>
              {showLeftColumn && (
                <SubscriptionInfo
                  podcast={podcast}
                  hasSubscription={hasSubscription}
                />
              )}
            </div>
            <div className={cn('lg:col-span-2')}>
              {hasSubscription && isBehindPaywall && <RssContainer />}
              {isAdmin ? (
                <>
                  <PostToFeed podcastId={podcastId} />
                  <PendingFeeds podcastId={podcastId} />
                </>
              ) : (
                <PodcastDescription podcast={podcast} />
              )}
              {hasSubscription &&
                podcast.tallyPoll?.id &&
                podcast.tallyPoll.visible && (
                  <TallyPoll
                    id={podcast.tallyPoll.id}
                    title={podcast.tallyPoll.title}
                  />
                )}
              <div className={cn('block', 'lg:hidden')}>
                <div className={cn(s.episodesContainer)}>
                  <PodcastEpisodes podcast={podcast} />
                </div>
              </div>
              {hasSubscription || !podcast.subscriptionPlans?.length ? (
                <>
                  <PodcastFeed podcastId={podcastId} slug={slug} />
                </>
              ) : !podcast.expiration ? (
                <FeedPreview podcastId={podcastId} slug={slug} />
              ) : null}
            </div>
            <div className={cn('hidden', 'lg:block')}>
              <div className={cn(s.episodesContainer)}>
                <PodcastEpisodes podcast={podcast} />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default PodcastDetailVisir;
