import React, { useRef, useState } from 'react';
import s from './UploadPicture.module.css';
import { useFirebase } from 'react-redux-firebase';
import { Spinner } from '../spinner/Spinner';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modal';
import { ModalType } from '../../types';
import { uuid } from '../../utils/utilities';
import { Button } from '@/components/ui/button';

type Props = {
  label: string;
  url?: string;
  save: (url: string) => Promise<void>;
  minCropBoxHeight?: number;
  minCropBoxWidth?: number;
  initialAspectRatio?: number;
  aspectRatio?: number;
};

export const UploadPicture: React.FC<Props> = ({
  save,
  label,
  url,
  minCropBoxHeight,
  minCropBoxWidth,
  initialAspectRatio,
  aspectRatio,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const id = uuid();
  const readFile = (files: any) => {
    if (!files.length) {
      return;
    }
    const file = files.item(0);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // setIsLoading(true);
    reader.onload = function () {
      dispatch(
        showModal(ModalType.CROPPER_MODAL, {
          src: reader.result,
          save,
          firebase,
          minCropBoxHeight,
          minCropBoxWidth,
          initialAspectRatio,
          aspectRatio,
        }),
      );
      //@ts-ignore
      document.getElementById(id).value = '';
    };
  };
  return (
    <>
      {!url ? (
        <Button variant={'outline'} type={'button'} onClick={() => ref.current?.click()}>Velja mynd</Button>
      ) : (
        <div className={s.preview}>
          <label htmlFor={id}>
            <img src={url + '?h=100'} alt={''} />
          </label>
          <div>
            <button
              className="underline text-purple-500"
              onClick={() => {
                save(null as any);
              }}
            >
              Hreinsa
            </button>
          </div>
        </div>
      )}
      <input
        className={s.hide}
        type="file"
        id={id}
        ref={ref}
        accept="image/*"
        onChange={(e) => readFile(e.target.files)}
      />
    </>
  );
};
