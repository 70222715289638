import withPodcast from '../utils/withPodcast';
import { PageContainer } from '../components/page-container/PageContainer';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { ManagePodcastTab, Podcast } from '../types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/react-form-utils/Form';
import { Button } from '../components/button/Button';
import { FormToggle } from '@/components/react-form-utils/FormToggle';
import { eventButtonFormSchema } from '../schemas/podcast-settings.schema';
import { useUpdateEventButton } from '../queries/podcast-settings';
import { success } from "../utils/Notifications";
import { FormInput } from "@/components/react-form-utils/FormInput";

const ManagePodcastSettings: React.FC<any> = (props) => {
  const {
    podcast,
  }: {
    podcast: Podcast;
  } = props;
  const { mutate, isPending, isSuccess } = useUpdateEventButton(podcast.id);
  const eventButtonForm = useForm<z.infer<typeof eventButtonFormSchema>>({
    resolver: zodResolver(eventButtonFormSchema),
    defaultValues: {
      text: podcast.eventButton?.text,
      url: podcast.eventButton?.url,
      visible: !!podcast.eventButton?.visible,
    },
  });
  useEffect(() => {
   if (isSuccess) {
     success('Stillingar hafa verið uppfærðar.')
   }
  }, [isSuccess]);
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.SETTINGS} podcast={podcast}>
        <Form
          onSubmit={(data) => {
            mutate(data);
          }}
          form={eventButtonForm}
        >
          <div
            className={
              'border border-gray-200 shadow-md p-4 rounded-md'
            }
          >
            <div className={'font-bold text-xl mb-4'}>
              Takki efst á hlaðvarpssíðu
            </div>
            <div className={'space-y-4'}>
              <FormInput
                name={'text'}
                label={'Texti í takkanum'}
                placeholder={'t.d. Opna'}
              />
              <FormInput
                name={'url'}
                label={'Slóð'}
                placeholder={'https://example.com'}
                className={'flex-1'}
              />
              <FormToggle name={'visible'} label={'Sýna takka'} />
              <Button type={'submit'} disabled={isPending}>
                Vista
              </Button>
            </div>
          </div>
        </Form>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastSettings);
