import { FieldError, useFormContext } from "react-hook-form";
import { ErrorLabel } from './ErrorLabel';
import cn from 'classnames';
import { ComponentProps, JSX } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Textarea } from "@/components/ui/textarea";
import { getNestedError } from "@/components/react-form-utils/common";

export const FormTextArea = ({
  name,
  label,
  className,
  ...props
}: Omit<ComponentProps<typeof Textarea>, 'name'> & {
  name: string;
  label?: string;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorField = getNestedError(errors, name) as FieldError | undefined;
  return (
    <div>
      {label && (
        <div>
          <label
            htmlFor={name}
            className={'mb-2 inline-block font-bold'}
          >
            {label}
          </label>
        </div>
      )}
      <Textarea
        id={name}
        {...register(name)}
        className={cn(
          'min-h-[120px]',
          className,
          'w-full rounded-md border p-3 placeholder-gray outline-0 resize-none',
        )}
        {...props}
      />
      {errorField && <ErrorLabel>{errorField.message}</ErrorLabel>}
    </div>
  );
};
