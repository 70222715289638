import React, { useState } from 'react';
import { FrontCategory, FrontCategoryLayout } from '../../types';
import { Button } from '../button/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { Center } from '../position/Center';
import { TextInput } from '../text-input/TextInput';
import { TitleCard } from '../title-card/TitleCard';
type Props = {
  cat?: FrontCategory;
  title: string;
  save: (cat: FrontCategory) => void;
  isLoading: boolean;
};
export const CategoryForm: React.FC<Props> = ({
  cat,
  title,
  save,
  isLoading,
}) => {
  const [name, setName] = useState<string>(cat?.name || '');
  const [layout, setLayout] = useState<FrontCategoryLayout>(
    cat?.layout || FrontCategoryLayout.DEFAULT
  );
  const validate = (): boolean => {
    if (!name) {
      return false;
    }
    return true;
  };
  const onSave = () => {
    const saveObj: any = { name, layout };
    if (cat?.id) {
      saveObj.id = cat.id;
    }
    save(saveObj);
  };
  const layoutMap = {
    [FrontCategoryLayout.DEFAULT]: 'Hefðbundin',
    [FrontCategoryLayout.LARGE]: 'Stærri',
  };
  return (
    <Center>
      <TitleCard title={title}>
        <TextInput
          autofocus
          value={name}
          label={'Nafn'}
          placeholder={'Nafn'}
          onChange={(val) => setName(val)}
        />
        <div className="mt-4">
          <Dropdown
            label={'Stærð'}
            options={[
              {
                name: layoutMap[FrontCategoryLayout.DEFAULT],
                val: FrontCategoryLayout.DEFAULT,
              },
              {
                name: layoutMap[FrontCategoryLayout.LARGE],
                val: FrontCategoryLayout.LARGE,
              },
            ]}
            onChange={(data) => {
              setLayout(data.val);
            }}
            selected={layoutMap[layout]}
          />
        </div>
        <div className={'mt-5'}>
          <Button className={'gray'} to={'/admin'}>
            Til baka
          </Button>
          <Button disabled={isLoading || !validate()} onClick={onSave}>
            {isLoading ? 'Hinkraðu...' : 'Vista'}
          </Button>
        </div>
      </TitleCard>
    </Center>
  );
};
