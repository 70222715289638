import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { PageContainer } from '../components/page-container/PageContainer';
import { Spinner } from '../components/spinner/Spinner';
import { Button } from '../components/button/Button';
import { FrontCategory, ModalType } from '../types';
import { OrderableList } from '../components/orderable-list/OrderableList';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';
import CategoryBusiness from '../business/CategoryBusiness';

const AdminPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const profile: any = useTypedSelector((state) => state.firebase.profile);
  const [orderedCategories, setOrderedCategories] = useState<FrontCategory[]>(
    []
  );
  const auth = useTypedSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const categoryBusiness = new CategoryBusiness(firestore);
  const history = useHistory();
  const dispatch = useDispatch();
  const getData = async () => {
    setIsLoading(true);
    const data = await categoryBusiness.getActiveCategories();
    setOrderedCategories(data.categories);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  if (!auth.isLoaded) {
    return <Spinner />;
  }
  if (!profile.isAdmin) {
    return null;
  }

  const changeOrder = (itemId: string, idxFrom: number, idxTo: number) => {
    const result = Array.from(orderedCategories);
    const [removed] = result.splice(idxFrom, 1);
    result.splice(idxTo, 0, removed);
    setOrderedCategories(result);
    categoryBusiness.updateOrdinal(result.map((r) => r.id as string));
  };

  return (
    <PageContainer>
      <div className="container mx-auto px-4">
        <div className="text-xl font-800 mt-8 border-b border-gray-200 pb-4 flex justify-between items-center">
          <h1>Flokkar á forsíðu</h1>
          <Button to={'/admin/new-category'}>+ Nýr flokkur</Button>
        </div>
        {isLoading && <Spinner />}
        {!isLoading && (
          <OrderableList
            className="mt-5"
            items={orderedCategories?.map((cat) => ({
              id: cat.id as string,
              name: cat.name,
              actions: [
                {
                  name: 'Opna',
                  onClick: () =>
                    history.push('/admin/manage-category/' + cat.id),
                },
                {
                  name: 'Breyta',
                  onClick: () => history.push('/admin/edit-category/' + cat.id),
                },
                {
                  name: 'Eyða',
                  onClick: () =>
                    dispatch(
                      showModal(ModalType.CONFIRM_MODAL, {
                        title: 'Eyða flokk',
                        body: 'Viltu fjarlægja þennan flokk?',
                        actionText: 'Já',
                        action: async () => {
                          await categoryBusiness.delete(cat.id as string);
                          setOrderedCategories(
                            orderedCategories.filter((c) => c.id !== cat.id)
                          );
                        },
                      })
                    ),
                  isRed: true,
                },
              ],
            }))}
            changeOrder={changeOrder}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default AdminPage;
