import React from 'react';
import { useFormContext } from 'react-hook-form';
import z from 'zod';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { useRequiredParam } from '@/hooks/use-required-param';
import { FormTextArea } from '@/components/react-form-utils/FormTextArea';
import { UploadSoundFile } from '@/components/upload-sound-file/UploadSoundFile';
import { FormToggle } from '@/components/react-form-utils/FormToggle';
import { PostToFeedV2 } from '@/components/post-to-feed-v2/PostToFeedV2';
import { FormInput } from '@/components/react-form-utils/FormInput';
import { UploadPicture as UploadPictureV2 } from '@/components-v2/UploadPicture';
import { UploadPicture } from '@/components/upload-picture/UploadPicture';
import cn from 'classnames';
import { Oval } from 'react-loader-spinner';
import { FaImage } from 'react-icons/fa';
import { FormDatePicker } from '@/components/react-form-utils/FormDatePicker';
import { scheduleEpisodeFormSchema } from "@/schemas/schedules";

export const EpisodesFormV2 = ({
  title,
  isLoading,
}: {
  title: string;
  isLoading: boolean;
}) => {
  const { setValue, watch } =
    useFormContext<z.infer<typeof scheduleEpisodeFormSchema>>();
  const podcastId = useRequiredParam('podcastId');
  return (
    <div className={'space-y-4'}>
      <div className={'flex justify-between items-center'}>
        <div className={'text-2xl font-bold'}>{title}</div>
        <div className={'space-x-2'}>
          <Link to={`/podcasts/uploads/${podcastId}`}>
            <Button type={'button'} variant={'outline'}>
              Hætta við
            </Button>
          </Link>
          <Button
            type={'submit'}
            disabled={isLoading || !watch('episode.img') || !watch('episode.url')}
          >
            Staðfesta
          </Button>
        </div>
      </div>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Hljóðþáttur</CardTitle>
            <CardDescription>Upplýsingar um þáttinn</CardDescription>
          </div>
          <FormToggle name={'episode.isOpen'} label={'Opið efni'} />
        </CardHeader>
        <CardContent className="space-y-4">
          <FormInput name={'episode.name'} label={'Nafn'} autoComplete={'off'} />
          <FormTextArea
            name={'episode.description'}
            label={'Lýsing'}
            autoComplete={'off'}
            className={'resize-none min-h-[120px]'}
          />
          <div className={'w-full flex items-center gap-3'}>
            <div>
              <UploadPicture
                aspectRatio={1}
                url={watch('episode.img')}
                label={''}
                save={async (link) => setValue('episode.img', link)}
              />
            </div>
            <div className={'flex-1'}>
              <UploadSoundFile
                url={watch('episode.url')}
                save={(url) => setValue('episode.url', url)}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Færsla með þætti</CardTitle>
            <CardDescription>
              Þú getur skrifað færslu sem mun birtast þegar þátturinn fer í
              loftið. Færslan getur einnig innihaldið myndband.
            </CardDescription>
          </div>
          <FormToggle name={'feed.enabled'} label={'Birta færslu með þætti'} />
        </CardHeader>
        {watch('feed.enabled') && (
          <CardContent className="space-y-4">
            <PostToFeedV2 />
          </CardContent>
        )}
      </Card>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Tilkynning</CardTitle>
            <CardDescription>
              Þú getur skrifað tilkynningu sem verður send á alla áskrifendur
              þegar þátturinn kemur út.
            </CardDescription>
          </div>
          <FormToggle name={'email.enabled'} label={'Senda tilkynningu'} />
        </CardHeader>
        {watch('email.enabled') && (
          <CardContent className="space-y-4">
            <FormInput name={'email.subject'} label={'Fyrirsögn'} />
            <FormTextArea name={'email.content'} label={'Efni'} />
            <UploadPictureV2
              onComplete={(url) =>
                setValue('email.content', `${watch('email.content')}${url}`)
              }
            >
              {(isPending) => (
                <>
                  <span
                    className={cn(
                      'transition-colors absolute w-[calc(100%_+_16px)] h-[calc(100%_+_16px)] rounded-lg -translate-x-1/2 top-1/2 left-1/2 -translate-y-1/2 z-0',
                      {
                        'group-hover:bg-gray-200': !isPending,
                      },
                    )}
                  />
                  <Oval
                    visible={isPending}
                    height="28"
                    secondaryColor={'#848484'}
                    width="28"
                    color="#211147"
                    strokeWidth={3}
                    wrapperClass="absolute -translate-x-1/2 top-1/2 left-1/2 -translate-y-1/2"
                  />
                  <FaImage className={'z-10 relative'} />
                </>
              )}
            </UploadPictureV2>
          </CardContent>
        )}
      </Card>
      <Card>
        <CardHeader className={'flex justify-between items-center'}>
          <div className={'space-y-2'}>
            <CardTitle>Tímasetning</CardTitle>
            <CardDescription>
              Ef þátturinn á ekki að koma út strax getur þú stillt
              tímasetninguna hér.
            </CardDescription>
          </div>
          <FormToggle name={'schedule.enabled'} label={'Tímasetja þáttinn'} />
        </CardHeader>
        {watch('schedule.enabled') && (
          <CardContent className="space-y-4">
            <FormDatePicker name={'schedule.publishAt'} />
          </CardContent>
        )}
      </Card>
    </div>
  );
};
