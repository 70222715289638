import type { InputHTMLAttributes, PropsWithChildren } from 'react';
import { DateTimePicker } from '@/components-v2/DateTimePicker';
import { FieldError, useFormContext } from 'react-hook-form';
import { getNestedError } from '@/components/react-form-utils/common';
import { ErrorLabel } from '@/components/react-form-utils/ErrorLabel';

export const FormDatePicker = ({
  name,
  label,
}: Omit<InputHTMLAttributes<HTMLButtonElement>, 'name'> & {
  name: string;
  label?: string;
}) => {
  const {
    watch,
    setValue,
clearErrors,
    formState: { errors },
  } = useFormContext();
  const date = watch(name) as Date | undefined;
  const errorField = getNestedError(errors, name) as FieldError | undefined;
  return (
    <div>
      {label && (
        <div>
          <label htmlFor={name} className={'mb-2 inline-block font-bold'}>
            {label}
          </label>
        </div>
      )}
      <DateTimePicker
        date={date}
        setDate={(val) => {
          setValue(name, val);
          clearErrors(name)
        }}
      />
      {errorField && <ErrorLabel>{errorField.message}</ErrorLabel>}
    </div>
  );
};
