import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { call } from '@/utils/ApiManager';
import { scheduleEpisodeFormSchema, scheduleSchema } from '@/schemas/schedules';
import z from 'zod';
import { zodParse } from '@/utils/zod-utils';

export function useCreateSchedule(podcastId: string) {
  return useMutation({
    mutationFn: (data: z.infer<typeof scheduleSchema>) => {
      return call(
        `/podcasts/${podcastId}/schedules`,
        false,
        null,
        'POST',
      )(zodParse(scheduleSchema, data));
    },
  });
}

export function useSchedules(
  podcastId: string,
  type: 'EPISODE' | 'EMAIL' | 'FEED',
) {
  return useQuery({
    queryKey: ['schedules', 'list', type, podcastId],
    queryFn: async () => {
      return zodParse(
        scheduleSchema.array(),
        await call(`/podcasts/${podcastId}/schedules?type=${type}`)(),
      );
    },
  });
}

export function useSchedule(scheduleId: string) {
  return useQuery({
    queryKey: ['schedules', 'list', scheduleId],
    queryFn: async () => {
      return zodParse(scheduleSchema, await call(`/schedules/${scheduleId}`)());
    },
  });
}

export function useEditSchedule(scheduleId: string) {
  return useMutation({
    mutationFn: (data: z.infer<typeof scheduleSchema>) => {
      return call(
        `/schedules/${scheduleId}`,
        false,
        null,
        'PATCH',
      )(zodParse(scheduleSchema, data));
    },
  });
}

export function useDeleteSchedule() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (scheduleId: string) =>
      call(`/schedules/${scheduleId}`, false, null, 'DELETE')(),
    onSettled: () => {
      void client.invalidateQueries({ queryKey: ['schedules'] });
    },
  });
}
