import React, { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { useUploadAttachment } from '@/queries/upload-attachment';

export const UploadPicture = ({
  onComplete,
  children,
}: {
  onComplete: (val: string) => void;
  children: (isPending: boolean) => ReactNode
}) => {
  const imgRef = useRef<HTMLInputElement>(null);
  const { data, isSuccess, isPending, mutate, reset } = useUploadAttachment();

  useEffect(() => {
    if (isSuccess) {
      onComplete(data);
      reset();
    }
  }, [data]);

  return (
    <button
      type={'button'}
      className={'relative group hover:cursor-pointer'}
      onClick={() => {
        if (imgRef.current && !isPending) {
          (imgRef?.current as any).click();
        }
      }}
    >
      {children(isPending)}
      <input
        ref={imgRef}
        className={'hidden'}
        type="file"
        id="image"
        accept="image/*"
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            mutate({ file });
          }
          e.target.value = '';
        }}
      />
    </button>
  );
};
