import React, { FC, PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import { Podcast } from '../../types';
import { PodcastPrice } from '../podcast-description/PodcastPrice';
import s from './PodcastSubscriptionFrame.module.css';
import clientConfig from '../../client-config';
import { Helmet } from 'react-helmet';
type Props = PropsWithChildren<{
  podcast: Podcast;
  subscriptionId?: string;
}>;
export const PodcastSubscriptionFrame: FC<Props> = ({
  podcast,
  subscriptionId,
  children,
}) => {
  const subscriptionPlan = podcast.subscriptionPlans?.length
    ? podcast.subscriptionPlans[0]
    : null;
  if (!subscriptionPlan) {
    return null;
  }
  if (podcast.expiration) {
    return (
      <div className={s.container}>
        <div className="p-5 border border-gray-200 shadow-lg rounded-lg font-bold text-center">
          Því miður er ekki lengur hægt að kaupa þessa áskrift.
        </div>
      </div>
    );
  }
  return (
    <div className={s.container}>
      <Helmet>
        <title>
          Kaupa áskrift - {podcast.name} | {clientConfig.title}
        </title>
        <link
          rel={'canonical'}
          href={clientConfig.canonical + '/podcasts/purchase/' + podcast.id}
        />
      </Helmet>
      {clientConfig.name === 'visir' && (
        <h1>
          {subscriptionId
            ? 'Uppfæra kortaupplýsingar'
            : 'Þú ætlar að gerast áskrifandi!'}
        </h1>
      )}
      {clientConfig.name !== 'visir' && (
        <div className={s.podcastName}>
          {subscriptionId ? 'Uppfæra kortaupplýsingar' : podcast.name}
        </div>
      )}
      <div className={s.frame}>
        <div className={s.top}>
          {podcast.subscriptionDescription && (
            <>
              <div className={s.left}>
                <ReactMarkdown>{podcast.subscriptionDescription}</ReactMarkdown>
              </div>
              <div className={s.spacer} />
            </>
          )}
          <div className={s.right}>
            <PodcastPrice podcast={podcast} />
            {!podcast.oneTimePurchase &&
              (!subscriptionId ? (
                <div className={s.info}>
                  Áskriftin endurnýjast eftir{' '}
                  {subscriptionPlan.months === 1
                    ? '1 mánuð'
                    : subscriptionPlan.months + ' mánuði'}
                </div>
              ) : (
                <div className={s.info}>Áskrift: {podcast.name}</div>
              ))}
          </div>
        </div>
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};
