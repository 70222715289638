import { useFirebase } from 'react-redux-firebase';
import { useTypedSelector } from '@/redux/reducers';
import { useMutation } from '@tanstack/react-query';
import { FileMimeType } from '@/utils/mime-types';
import { uuid } from '@/utils/utilities';
import { useState } from 'react';
import { UploadTask } from '@firebase/storage-types';

export function useUploadFile() {
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const userId = auth.uid;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadTask, setUploadTask] = useState<UploadTask>();
  const mutation = useMutation({
    mutationFn: async ({
      file,
      urlType,
    }: {
      file: File;
      urlType?: 'IMGIX' | 'STORAGE';
    }) => {
      let fileName =
        uuid() +
        '.' +
        (FileMimeType[file.type as keyof typeof FileMimeType] || '.jpg');
      const uploadTask = firebase
        .storage()
        .ref('/attachments/' + userId + '/' + fileName)
        .put(file);
      setUploadTask(uploadTask);
      return new Promise<{ name: string; url: string }>((resolve, reject) => {
        uploadTask.on(
          (firebase as any).storage.TaskEvent.STATE_CHANGED,
          (snapshot: any) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error: any) => {
            console.error(error);
            reject(error);
          },
          () => {
            if (urlType === 'IMGIX') {
              resolve({
                name: file.name,
                url:
                  process.env.REACT_APP_IMGIX +
                  '/attachments/' +
                  userId +
                  '/' +
                  fileName,
              });
            } else {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then((url) => resolve({ name: file.name, url }))
                .catch(reject);
            }
          },
        );
      });
    },
    onSettled: () => setUploadProgress(0),
  });
  return { mutation, uploadProgress, uploadTask };
}
